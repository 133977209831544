// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getToken, onMessage } from "firebase/messaging";

import {
  connectAuthEmulator,
  getAuth,
  GoogleAuthProvider,
} from "firebase/auth";

const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.addScope(
  "https://www.googleapis.com/auth/contacts.readonly"
);

const firebaseConfig = {
  apiKey: "AIzaSyD0lMkfO00ceJVptoRJVDT9zpL_RKjtY0c",
  authDomain: "appnoormarkunvpkfi.firebaseapp.com",
  projectId: "appnoormarkunvpkfi",
  storageBucket: "appnoormarkunvpkfi.appspot.com",
  messagingSenderId: "59816375117",
  appId: "1:59816375117:web:695704b96292260089bd04",
  measurementId: "G-WBFE3PKRGG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const firebaseStorage = getStorage(app);
const ilmoitusStorage = getStorage(app, "gs://ilmoitus");
const ilmoitusHallitusStorage = getStorage(app, "gs://ilmoitus_hallitus");
const tiedostopankkiStorage = getStorage(app, "gs://tiedostopankki");
const tiedostopankkiHallitusStorage = getStorage(
  app,
  "gs://tiedostopankki_hallitus"
);
const digivihotStorage = getStorage(app, "gs://digivihot");
const kalustoStorage = getStorage(app, "gs://kalusto");
/*const tyosuojelutoimikuntaStorage = getStorage(
  app,
  "gs://novpk_tyosuojelutoimikunta"
);*/

const firebaseFunctions = getFunctions(app, "europe-west1");

const auth = getAuth(app);
auth.languageCode = "fi";
if (process.env.NODE_ENV === "development") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
  connectStorageEmulator(firebaseStorage, "localhost", 9199);
  connectStorageEmulator(ilmoitusStorage, "localhost", 9199);
  connectStorageEmulator(ilmoitusHallitusStorage, "localhost", 9199);
  connectStorageEmulator(digivihotStorage, "localhost", 9199);
  connectStorageEmulator(kalustoStorage, "localhost", 9199);
  connectStorageEmulator(tiedostopankkiHallitusStorage, "localhost", 9199);
  connectStorageEmulator(tiedostopankkiStorage, "localhost", 9199);
  //connectStorageEmulator(tyosuojelutoimikuntaStorage, "localhost", 9199);
  connectFirestoreEmulator(firestore, "localhost", 8082);
}

export default app;
export {
  analytics,
  firestore,
  firebaseStorage as storage,
  ilmoitusStorage,
  ilmoitusHallitusStorage,
  digivihotStorage,
  tiedostopankkiStorage,
  tiedostopankkiHallitusStorage,
  kalustoStorage,
  //tyosuojelutoimikuntaStorage,
  firebaseFunctions as functions,
  auth,
  googleAuthProvider,
  getToken,
  onMessage,
};
