<script>
import { firestore as db, kalustoStorage } from "@/plugins/firebase.app";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import PictureDialog from "@/views/PictureDialog.vue";

const locationsTable = "kalusto_sijainnit";
export default {
  name: "KalustoSijaintiEditDialog",
  components: { PictureDialog },

  data() {
    return {
      dialog: false, // Add/Edit dialog visibility
      dialogTitle: "", // Title of the dialog
      dialogData: {}, // Data being edited or added
      selectedPicture: null,
      selectedThumbnail: null,
      loadingSave: false,
      editMode: false,
    };
  },
  emits: ["close"],
  watch: {
    dialog(open) {
      if (!open) {
        this.dialogData = {};
        this.selectedPicture = null;
        this.selectedThumbnail = null;
        this.$emit("close");
      }
    },
  },
  methods: {
    open(data) {
      this.dialogData = {};
      this.editMode = false;
      this.$nextTick(() => {
        this.dialogData = {
          pictureFile1: null,
          pictureFile2: null,
          pictureFile3: null,
          ...data,
        };
        this.dialogTitle = data.id ? "Sijainti" : "Lisää uusi sijainti";
        if (!data.id) {
          this.editMode = true;
        }
        this.dialog = true;
      });
    },
    edit() {
      this.editMode = true;
    },
    // Handle image file selection
    onPictureSelected(e, i) {
      const file = e.target.files[0];
      this.dialogData[`pictureFile${i}`] = file;
    },
    async uploadLocationPicture(file) {
      if (!file) return "";
      if (!this.dialogData.id) {
        return;
      }
      const date = Date.now();
      const storage = kalustoStorage;
      const originalFilePath = `kalusto-sijainti-kuvat/${this.dialogData.id}/${date}-${file.name}`;
      const thumbnailFilePath = `kalusto-sijainti-kuvat/${this.dialogData.id}/${date}-thumbnail-${file.name}`;

      // Create a thumbnail of the image
      const thumbnail = await this.$createThumbnail(file);
      const picture = await this.$createPicture(file);

      // Upload original image to Firebase Storage
      const originalStorageRef = ref(storage, originalFilePath);
      await uploadBytes(originalStorageRef, picture);
      //await uploadBytes(originalStorageRef, file); // This will upload the original size image

      // Upload thumbnail image to Firebase Storage
      const thumbnailStorageRef = ref(storage, thumbnailFilePath);
      await uploadBytes(thumbnailStorageRef, thumbnail);

      // Get download URLs for both images
      const originalDownloadURL = await getDownloadURL(originalStorageRef);
      const thumbnailDownloadURL = await getDownloadURL(thumbnailStorageRef);

      // Return the URLs or do whatever is necessary
      return {
        originalURL: originalDownloadURL,
        thumbnailURL: thumbnailDownloadURL,
      };
    },

    async fetchLocation(id) {
      try {
        const docRef = await getDoc(doc(db, locationsTable, id));

        this.open({ id: docRef.id, ...docRef.data() });
      } catch (e) {
        console.error(e);
        this.$toast("Virhe haettaessa sijaintia", { type: "error" });
      }
    },
    // Save the node (add or edit)
    async saveNode() {
      if (!this.dialogData.name.trim()) {
        this.$toast("Sijainnin nimi on määritettävä", { type: "error" });
        return;
      }
      this.loadingSave = true;
      try {
        const pictureURLs = await this.uploadLocationPicture(
          this.dialogData.pictureFile1
        );
        const pictureURLs2 = await this.uploadLocationPicture(
          this.dialogData.pictureFile2
        );
        const pictureURLs3 = await this.uploadLocationPicture(
          this.dialogData.pictureFile3
        );

        const data = {
          name: this.dialogData.name,
          parent: this.dialogData.parent,
          deleted: this.dialogData.deleted,
          picture: pictureURLs?.originalURL || this.dialogData.picture || null,
          pictureThumbnail:
            pictureURLs?.thumbnailURL ||
            this.dialogData.pictureThumbnail ||
            null,
          picture2:
            pictureURLs2?.originalURL || this.dialogData.picture2 || null,
          pictureThumbnail2:
            pictureURLs2?.thumbnailURL ||
            this.dialogData.pictureThumbnail2 ||
            null,
          picture3:
            pictureURLs3?.originalURL || this.dialogData.picture3 || null,
          pictureThumbnail3:
            pictureURLs3?.thumbnailURL ||
            this.dialogData.pictureThumbnail3 ||
            null,
        };
        if (this.dialogData.id) {
          // Update existing location
          await updateDoc(doc(db, locationsTable, this.dialogData.id), data);
        } else {
          // Add new location
          const docRef = await addDoc(collection(db, locationsTable), data);
          this.dialogData.id = docRef.id;
        }
        await this.fetchLocation(this.dialogData.id);
      } catch (e) {
        console.error(e);
        this.$toast("Virhe tallennuksessa", { type: "error" });
      }
      this.loadingSave = false;
      //this.dialog = false;
    },
  },
};
</script>

<template>
  <!-- Add/Edit Location Dialog -->
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-card-title>
        <v-row no-gutters
          >{{ (dialogData.id && dialogData.name) || dialogTitle }}
          <v-spacer />
          <v-btn @click="editMode = true" small text v-if="!editMode"
            ><v-icon>mdi mdi-pencil</v-icon> Muokkaa</v-btn
          >
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text v-if="!editMode">
        <v-row>
          <v-col cols="12" class="mt-5"> {{ dialogData._fullTree }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <strong>Sijainti:</strong> {{ dialogData.name }}
          </v-col>
          <v-col cols="12">
            <v-divider class="mt-4 mb-3" />
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="mb-3"
                :key="i"
                v-for="(pic, i) in [
                  { picture: 'picture', thumbnail: 'pictureThumbnail' },
                  { picture: 'picture2', thumbnail: 'pictureThumbnail2' },
                  { picture: 'picture3', thumbnail: 'pictureThumbnail3' },
                ]"
              >
                <template v-if="dialogData[pic['picture']]">
                  <strong>Kuva {{ i + 1 }}:</strong>
                  <br />
                  <div class="mt-5" v-if="dialogData[pic['picture']]">
                    <v-img
                      :src="dialogData[pic['thumbnail']]"
                      max-height="200"
                      contain
                      @click="
                        () => {
                          selectedPicture = dialogData[pic['picture']];
                          selectedThumbnail = dialogData[pic['thumbnail']];
                        }
                      "
                    />
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="editMode">
        <v-row>
          <v-col cols="12" class="mt-5"> {{ dialogData._fullTree }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Sijainnin nimi"
              v-model="dialogData.name"
              autofocus
              required
              outlined
            ></v-text-field>
          </v-col>

          <template v-if="dialogData.id">
            <v-col
              cols="12"
              md="6"
              class="mb-3"
              :key="i"
              v-for="(pic, i) in [
                { picture: 'picture', thumbnail: 'pictureThumbnail' },
                { picture: 'picture2', thumbnail: 'pictureThumbnail2' },
                { picture: 'picture3', thumbnail: 'pictureThumbnail3' },
              ]"
            >
              <strong>Kuva {{ i + 1 }}:</strong>
              <br />
              <v-row>
                <v-col cols="auto">
                  <input
                    type="file"
                    :id="`pictureUpload${i + 1}`"
                    accept="image/*"
                    @change="(e) => onPictureSelected(e, i + 1)"
                  />
                </v-col>
              </v-row>

              <div class="mt-5" v-if="dialogData[pic['picture']]">
                <v-img
                  :src="dialogData[pic['thumbnail']]"
                  max-height="200"
                  contain
                  @click="
                    () => {
                      selectedPicture = dialogData[pic['picture']];
                      selectedThumbnail = dialogData[pic['thumbnail']];
                    }
                  "
                />
              </div>
              <v-row
                class="mt-2"
                v-if="dialogData[pic['picture']]"
                justify="center"
              >
                <v-col cols="auto">
                  <v-btn
                    small
                    text
                    color="error"
                    @click="
                      () => {
                        dialogData[pic['picture']] = null;
                        dialogData[pic['thumbnail']] = null;
                      }
                    "
                    v-if="dialogData[pic['picture']]"
                    ><v-icon small>mdi mdi-delete</v-icon> Poista kuva
                    {{ i + 1 }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" text>Sulje</v-btn>
        <v-btn
          color="primary"
          @click="saveNode"
          :loading="loadingSave"
          v-if="editMode"
          >Tallenna</v-btn
        >
      </v-card-actions>
      <picture-dialog
        v-model="selectedPicture"
        :thumbnail="selectedThumbnail"
      />
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
