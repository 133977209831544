var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_vm._v(_vm._s((_vm.dialogData.id && _vm.dialogData.name) || _vm.dialogTitle)+" "),_c('v-spacer'),(!_vm.editMode)?_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){_vm.editMode = true}}},[_c('v-icon',[_vm._v("mdi mdi-pencil")]),_vm._v(" Muokkaa")],1):_vm._e()],1)],1),_c('v-divider'),(!_vm.editMode)?_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.dialogData._fullTree))])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Sijainti:")]),_vm._v(" "+_vm._s(_vm.dialogData.name)+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mt-4 mb-3"})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',_vm._l(([
                { picture: 'picture', thumbnail: 'pictureThumbnail' },
                { picture: 'picture2', thumbnail: 'pictureThumbnail2' },
                { picture: 'picture3', thumbnail: 'pictureThumbnail3' },
              ]),function(pic,i){return _c('v-col',{key:i,staticClass:"mb-3",attrs:{"cols":"12","md":"4"}},[(_vm.dialogData[pic['picture']])?[_c('strong',[_vm._v("Kuva "+_vm._s(i + 1)+":")]),_c('br'),(_vm.dialogData[pic['picture']])?_c('div',{staticClass:"mt-5"},[_c('v-img',{attrs:{"src":_vm.dialogData[pic['thumbnail']],"max-height":"200","contain":""},on:{"click":() => {
                        _vm.selectedPicture = _vm.dialogData[pic['picture']];
                        _vm.selectedThumbnail = _vm.dialogData[pic['thumbnail']];
                      }}})],1):_vm._e()]:_vm._e()],2)}),1)],1)],1)],1):_vm._e(),(_vm.editMode)?_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.dialogData._fullTree))])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Sijainnin nimi","autofocus":"","required":"","outlined":""},model:{value:(_vm.dialogData.name),callback:function ($$v) {_vm.$set(_vm.dialogData, "name", $$v)},expression:"dialogData.name"}})],1),(_vm.dialogData.id)?_vm._l(([
              { picture: 'picture', thumbnail: 'pictureThumbnail' },
              { picture: 'picture2', thumbnail: 'pictureThumbnail2' },
              { picture: 'picture3', thumbnail: 'pictureThumbnail3' },
            ]),function(pic,i){return _c('v-col',{key:i,staticClass:"mb-3",attrs:{"cols":"12","md":"6"}},[_c('strong',[_vm._v("Kuva "+_vm._s(i + 1)+":")]),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('input',{attrs:{"type":"file","id":`pictureUpload${i + 1}`,"accept":"image/*"},on:{"change":(e) => _vm.onPictureSelected(e, i + 1)}})])],1),(_vm.dialogData[pic['picture']])?_c('div',{staticClass:"mt-5"},[_c('v-img',{attrs:{"src":_vm.dialogData[pic['thumbnail']],"max-height":"200","contain":""},on:{"click":() => {
                    _vm.selectedPicture = _vm.dialogData[pic['picture']];
                    _vm.selectedThumbnail = _vm.dialogData[pic['thumbnail']];
                  }}})],1):_vm._e(),(_vm.dialogData[pic['picture']])?_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.dialogData[pic['picture']])?_c('v-btn',{attrs:{"small":"","text":"","color":"error"},on:{"click":() => {
                      _vm.dialogData[pic['picture']] = null;
                      _vm.dialogData[pic['thumbnail']] = null;
                    }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi mdi-delete")]),_vm._v(" Poista kuva "+_vm._s(i + 1))],1):_vm._e()],1)],1):_vm._e()],1)}):_vm._e()],2)],1):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Sulje")]),(_vm.editMode)?_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingSave},on:{"click":_vm.saveNode}},[_vm._v("Tallenna")]):_vm._e()],1),_c('picture-dialog',{attrs:{"thumbnail":_vm.selectedThumbnail},model:{value:(_vm.selectedPicture),callback:function ($$v) {_vm.selectedPicture=$$v},expression:"selectedPicture"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }