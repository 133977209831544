<template>
  <v-dialog
    v-model="innerValue"
    max-width="600"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title>
        <v-icon class="mr-2">mdi mdi-face-mask-outline</v-icon>
        Paineilmalaitehuolto
      </v-card-title>
      <v-divider class="mb-2" />
      <v-card-text>
        <v-row>
          <v-col cols="6" class="d-flex justify-end align-center">
            <v-select
              v-model="partsForm.device"
              :items="devices"
              item-value="id"
              item-text="name"
              label="Laite"
              :disabled="!hasEditPermissions"
              outlined
              hide-details
            />
          </v-col>
          <v-col cols="6" class="d-flex justify-end align-center">
            <modal-picker
              v-model="partsForm.date"
              label="Päivämäärä"
              :disabled="!hasEditPermissions"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="partsForm.device && partsForm.date">
        <v-row v-for="device in [partsForm.device]" :key="device">
          <v-col cols="12" v-if="partsForm.id">
            <div v-if="partsForm && partsForm.createdAt">
              Luotu:
              {{ $formatDateAndHourMinute(partsForm.createdAt) }} -
              <span v-if="partsForm.createdBy">{{
                partsForm.createdBy.user
              }}</span>
            </div>
            <div v-if="partsForm && partsForm.updatedAt">
              Päivitetty:
              {{ $formatDateAndHourMinute(partsForm.updatedAt) }} -
              <span v-if="partsForm.updatedBy">{{
                partsForm.updatedBy.user
              }}</span>
            </div>
            <v-divider v-if="partsForm.id" />
          </v-col>

          <v-col cols="12" v-if="partsForm">
            <template v-for="(ep, i) in partsForm.form">
              <v-divider
                v-if="ep.type === 'title' && i !== 0"
                :key="`divider_${i}`"
                class="mt-6"
              />
              <v-row :key="i">
                <template v-if="ep.type === 'title'">
                  <v-col
                    cols="12"
                    class="align-center justify-space-between mt-5 border-bottom"
                  >
                    <span class="text-subtitle-1 font-weight-bold">{{
                      ep.title
                    }}</span>
                  </v-col>
                </template>
                <template v-else-if="ep.type === 'title2'">
                  <v-col cols="12" class="align-center justify-space-between">
                    <span class="text-subtitle-2 font-weight-bold">{{
                      ep.title
                    }}</span>
                  </v-col>
                </template>
                <template v-else-if="ep.type === 'pressure_select'">
                  <v-col cols="12" class="align-center justify-space-between">
                    <span class="text-subtitle-2">{{ ep.title }}</span>
                    <v-select
                      class="mt-3"
                      v-model="ep.pressure"
                      :items="[250, 260, 270, 280, 290, 300, 310]"
                      label="Valitse paine"
                      outlined
                      hide-details
                      :disabled="!hasEditPermissions"
                    />
                  </v-col>
                </template>
                <template v-else>
                  <v-col
                    cols="9"
                    :class="[
                      'd-flex align-center justify-space-between py-1',
                      ep.done === 2 && 'green lighten-5 green--text',
                      ep.done === 1 && 'orange lighten-5 orange--text',
                      ep.done === 0 && 'red lighten-5 red--text',
                      'border-bottom',
                    ]"
                  >
                    <span class="text-subtitle-2">
                      {{ ep.title }}
                      <v-icon
                        v-if="ep.done === 2"
                        class="green lighten-5 green--text"
                        >mdi mdi-check</v-icon
                      >
                      <v-icon
                        v-if="ep.done === 1"
                        class="orange lighten-5 orange--text"
                        >mdi mdi-exclamation-thick</v-icon
                      >
                      <v-icon
                        v-if="ep.done === 0"
                        class="red lighten-5 red--text"
                        >mdi mdi-alert</v-icon
                      >
                    </span>

                    <span class="caption" v-if="ep.description">{{
                      ep.description
                    }}</span>
                  </v-col>
                  <v-col
                    cols="3"
                    :class="[
                      'd-flex align-center py-1',
                      ep.done === 2 && 'green lighten-5 green--text',
                      ep.done === 1 && 'orange lighten-5 orange--text',
                      ep.done === 0 && 'red lighten-5 red--text',
                      'border-bottom',
                      'justify-end',
                    ]"
                  >
                    <v-btn
                      icon
                      @click="() => (ep.done = 2)"
                      v-if="!ep.done && ep.done !== 0"
                      :disabled="!hasEditPermissions"
                    >
                      <v-icon>mdi mdi-check</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      @click="() => (ep.done = 1)"
                      :disabled="!hasEditPermissions"
                      v-if="ep.done === 2"
                    >
                      <v-icon>mdi mdi-check</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      @click="() => (ep.done = 0)"
                      v-if="ep.done === 1"
                      :disabled="!hasEditPermissions"
                    >
                      <v-icon>mdi mdi-check</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      @click="() => (ep.done = null)"
                      v-if="ep.done === 0"
                      :disabled="!hasEditPermissions"
                    >
                      <v-icon>mdi mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
              <!--<v-divider :key="`divider_${i}`" />-->
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <v-textarea
                  outlined
                  auto-grow
                  label="Huomiot"
                  v-model="partsForm.notices"
                  :disabled="!hasEditPermissions"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          v-if="partsForm?.id && hasEditPermissions"
          :loading="loadingDelete"
          color="error"
          @click="deleteForm"
          >Poista
        </v-btn>
        <v-spacer />
        <v-btn color="error" text @click="innerValue = false"> Sulje</v-btn>

        <v-btn
          color="primary"
          @click="saveForm"
          :loading="loadingSave"
          v-if="hasEditPermissions"
          >Tallenna</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { firestore as db } from "@/plugins/firebase.app";
import ModalPicker from "@/components/ModalPicker.vue";

export default {
  name: "PaineilmalaiteHuoltoDialog",
  components: { ModalPicker },
  props: {},
  data() {
    return {
      selectedDevice: null,
      innerValue: false,
      loadingDelete: false,
      loadingSave: false,
      devices: [
        {
          id: 1,
          name: "Laite 1",
        },
        {
          id: 2,
          name: "Laite 2",
        },
        {
          id: 3,
          name: "Laite 3",
        },
        {
          id: 4,
          name: "Laite 4",
        },
        {
          id: 5,
          name: "Laite 5",
        },
        {
          id: 6,
          name: "Laite 6",
        },
        {
          id: 7,
          name: "Laite 7",
        },
        {
          id: 8,
          name: "Laite 8",
        },
        {
          id: 9,
          name: "Laite 9",
        },
        {
          id: 10,
          name: "Laite 10",
        },
        {
          id: 11,
          name: "Laite 11",
        },
      ],
      partsForm: {
        createdAt: null,
        createdBy: null,
        device: null,
        date: null,
        form: [],
        notices: null,
      },
      defaultForm: [
        {
          title: "1. Henkilösuojainten käyttö",
          type: "title",
          done: false,
          description: null,
        },
        {
          title: "Hengityksen suojain",
          type: "title2",
          done: false,
          description: null,
        },
        {
          title: "Suojakäsineet",
          type: "title2",
          done: false,
          description: null,
        },
        {
          title: "Silmäsuojus",
          type: "title2",
          done: false,
          description: null,
        },
        {
          title: "Kuulonsuojaus painepesuria käytettäessä",
          type: "title2",
          done: false,
          description: null,
        },
        {
          title: "2. Puhdistus ja kuivaus",
          type: "title",
          done: false,
          description: null,
        },
        {
          title: "Raakapesu paineistettuna (pesuaine + varovainen pesu)",
          type: null,
          done: null,
          description: null,
        },
        {
          title:
            "Kasvo-osan pesu (saa irroittaa vain liekkisuojan ja sisäkasvo-osan)",
          type: null,
          done: null,
          description: null,
        },
        {
          title: "Hengitysventtiilin pyyhintä",
          type: null,
          done: null,
          description: null,
        },
        {
          title: "Pullon suojapussin pesu",
          type: null,
          done: null,
          description: null,
        },
        {
          title:
            "Selkälevyn, kasvo-osan ja hengitysventtiilin kuivaus kaapissa",
          type: null,
          done: null,
          description: null,
        },
        {
          title: "3. Käyttökuntoon laittaminen",
          type: "title",
          done: false,
          description: null,
        },
        {
          title: "Kasaaminen (sama juokseva numero sl+hv+k-o)",
          type: null,
          done: null,
          description: null,
        },
        {
          title: "Puhdas suojapussi pulloon",
          type: null,
          done: null,
          description: null,
        },
        {
          title: "Hihnojen löysääminen",
          type: null,
          done: null,
          description: null,
        },
        {
          title: "4. Toimintakuntotarkastus",
          type: "title",
          done: false,
          description: null,
        },
        {
          title: "Hihnaston ja letkuston silmämääräinen tarkastus",
          type: null,
          done: null,
          description: null,
        },
        {
          title: "Pullopaine (oltava yli 250 bar)",
          type: "pressure_select",
          done: null,
          description: null,
          pressure: null,
        },
        {
          title: "Tiiveyskoe (+/- 10 bar / min)",
          type: null,
          done: null,
          description: null,
        },
        {
          title: "Kasvo-osan tiiveys ja uloshengitysventtiilin toiminta",
          type: null,
          done: null,
          description: null,
        },
        {
          title: "Kasvo-osan pikakiinnikkeiden kunto ja toiminta",
          type: null,
          done: null,
          description: null,
        },
        {
          title: "Pillivaroittimen toiminta",
          type: null,
          done: null,
          description: null,
        },
        {
          title: "5. Sijoittaminen autoon",
          type: "title",
          done: false,
          description: null,
        },
        {
          title: "Laitekierto 511 -> 5112",
          type: null,
          done: null,
          description: null,
        },
        {
          title: "Sijoitus oikein telineeseen, että on puettavissa",
          type: null,
          done: null,
          description: null,
        },
        {
          title: "Kasvo-osan sijoitus pidikkeeseen ulkopuoli päin takaseinää",
          type: null,
          done: null,
          description: null,
        },
      ],
    };
  },
  computed: {
    hasEditPermissions() {
      return (
        !this.partsForm.id ||
        this.$userDBContext.sub === this.partsForm.createdBy.sub ||
        this.$isSuperAdmin
      );
    },
  },
  watch: {
    innerValue: {
      handler(val) {
        if (!val) this.$emit("close", val);
      },
    },
  },
  methods: {
    open(partsForm) {
      this.innerValue = true;
      if (partsForm) {
        this.partsForm = partsForm;
      } else {
        this.resetPartsForm();
      }
    },
    resetPartsForm() {
      this.partsForm = {
        createdAt: null,
        createdBy: null,
        device: null,
        date: null,
        // eslint-disable-next-line no-undef
        form: structuredClone(this.defaultForm),
        notices: null,
      };
    },
    async saveForm() {
      if (!this.partsForm.date) {
        this.$toast("Päivämäärä on pakollinen", {
          type: "error",
        });
        return;
      }
      if (!this.partsForm.device) {
        this.$toast("Laite on pakollinen", {
          type: "error",
        });
        return;
      }
      const pressureSelect = this.partsForm.form.find(
        (e) => e.type === "pressure_select"
      );
      if (pressureSelect && !pressureSelect?.pressure) {
        this.$toast("Paine on pakollinen", {
          type: "error",
        });
        return;
      }
      this.loadingSave = true;
      try {
        if (this.partsForm?.id) {
          // update
          if (!this.hasEditPermissions) {
            this.$toast(
              "Et voi muokata toisen käyttäjän tallentamaa huoltomerkintä",
              {
                type: "error",
              }
            );
            this.loadingSave = false;
            return;
          }
          await updateDoc(doc(db, "paineilmalaitehuolto", this.partsForm.id), {
            ...this.partsForm,
            updatedAt: Date.now(),
            updatedBy: this.$userDBContext,
            deleted: false,
          });
        } else {
          await addDoc(collection(db, "paineilmalaitehuolto"), {
            ...this.partsForm,
            createdBy: this.$userDBContext,
            createdAt: Date.now(),
            deleted: false,
          });
        }
        this.$toast("Paineilmalaitteen huoltomerkinnän tallennus onnistui", {
          type: "success",
        });
        this.innerValue = false;
        this.partsForm = null;
        this.resetPartsForm();
      } catch (e) {
        this.$toast("Paineilmalaitteen huoltomerkinnän tallennus epäonnistui", {
          type: "error",
        });
        console.error(e);
      }

      this.loadingSave = false;
    },
    async deleteForm() {
      if (!this.hasEditPermissions) {
        this.$toast(
          "Et voi poistaa toisen käyttäjän tallentamaa huoltomerkintää",
          {
            type: "error",
          }
        );
        return;
      }
      if (
        !(await this.$deleteConfirm(
          "Haluatko varmasti poistaa huoltomerkinnän?"
        ))
      ) {
        return;
      }
      this.loadingDelete = true;
      try {
        await updateDoc(doc(db, "paineilmalaitehuolto", this.partsForm.id), {
          deleted: true,
          deletedAt: Date.now(),
          deletedBy: this.$userDBContext,
        });
        this.innerValue = false;
        this.partsForm = null;
        this.resetPartsForm();
      } catch (e) {
        console.error(e);
        this.$toast("Virhe poistettaessa", { type: "error" });
      }
      this.loadingDelete = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
