<template>
  <basic-page
    :fallback="{ name: 'Digivihot' }"
    :fluid="true"
    force-fallback
    prev
    title="Digivihko"
  >
    <v-row class="fill-height">
      <v-col cols="12">
        <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
        <v-form ref="form">
          <v-card v-if="!loading">
            <v-card-title
              >Digivihko&nbsp;
              <v-spacer />
              <small
                >{{ digivihko.code }} {{ digivihko.address }}
                {{ digivihko.subject }}</small
              ></v-card-title
            >
            <v-divider />
            <v-card-text>
              <v-col cols="12">
                <v-btn-toggle
                  v-model="digivihko.type"
                  color="info"
                  dense
                  mandatory
                >
                  <v-btn
                    :disabled="
                      !!digivihko.id || digivihko.participants.length > 0
                    "
                    value="Hälytys"
                    >Hälytys
                  </v-btn>
                  <v-btn
                    :disabled="
                      !!digivihko.id || digivihko.participants.length > 0
                    "
                    value="Harjoitus"
                    >Harjoitus
                  </v-btn>
                  <v-btn
                    :disabled="
                      !!digivihko.id || digivihko.participants.length > 0
                    "
                    value="Muu"
                    >Muu
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-card-text>
            <v-divider />
          </v-card>
          <template
            v-if="digivihko.type === 'Harjoitus' || digivihko.type === 'Muu'"
          >
            <v-card class="mt-2">
              <v-card-text>
                <v-row>
                  <v-col cols="12" lg="9">
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          v-model="digivihko.subject"
                          :rules="[$rules.required]"
                          hide-details
                          label="Aihe"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col v-if="digivihko.type === 'Muu'" cols="12" lg="6">
                        <v-select
                          v-model="digivihko.muuType"
                          :items="muuTypes"
                          :rules="[$rules.required]"
                          hide-details
                          item-text="label"
                          item-value="value"
                          label="Tyyppi"
                          outlined
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-row>
                  <v-col cols="12" lg="3">
                    <modal-picker
                      v-model="digivihko.date"
                      :allowed-dates="allowedDates"
                      label="Aloituspäivämäärä"
                    />
                  </v-col>
                  <v-col cols="8" lg="2" v-if="digivihko.type !== 'Harjoitus'">
                    <modal-picker
                      v-model="digivihko.time"
                      label="Aloitusaika"
                      type="time"
                    />
                  </v-col>
                  <v-col cols="4" lg="1"
                    ><v-btn icon class="mt-3" @click="syncParticipantStartTime"
                      ><v-icon>mdi mdi-sync</v-icon></v-btn
                    ></v-col
                  >
                </v-row>
              </v-card-text>
              <v-card-text v-if="digivihko.type !== 'Harjoitus'">
                <v-row>
                  <v-col cols="12" lg="3">
                    <modal-picker
                      v-model="digivihko.dateReturn"
                      :allowed-dates="allowedDates2"
                      :error="diff < 0"
                      label="Lopetuspäivämäärä"
                    />
                  </v-col>
                  <v-col cols="8" lg="2">
                    <modal-picker
                      v-model="digivihko.timeReturn"
                      :error="diff < 0"
                      label="Lopetusaika"
                      type="time"
                    />
                  </v-col>
                  <v-col cols="4" lg="1"
                    ><v-btn icon class="mt-3" @click="syncParticipantReturnTime"
                      ><v-icon>mdi mdi-sync</v-icon></v-btn
                    ></v-col
                  >
                  <v-col v-if="diff < 0" cols="12">
                    <v-alert type="error"
                      >Paluuajan pitää olla lähtöajan jälkeen
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider v-if="digivihko.type !== 'Harjoitus'" />
              <v-card-title v-if="digivihko.type !== 'Harjoitus'"
                >Kesto</v-card-title
              >
              <v-card-text v-if="digivihko.type !== 'Harjoitus'">
                <v-row>
                  <v-col
                    :class="[diff < 0 && 'error--text']"
                    class="text-center text-body-1"
                    cols="12"
                    lg="3"
                  >
                    {{ durationAsMinutes }} minuuttia

                    <br />
                    <v-row align="center" class="mt-1 mb-1">
                      <v-divider></v-divider>
                      <span class="ml-2 mr-2">tai</span>
                      <v-divider></v-divider>
                    </v-row>
                    {{ duration }}
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-row>
                  <v-col cols="12" lg="9">
                    <v-textarea
                      v-model="digivihko.description"
                      auto-grow
                      hide-details
                      label="Kuvaus"
                      outlined
                      persistent-placeholder
                      placeholder="Valinnainen kuvaus"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-2" v-if="digivihko.type === 'Harjoitus'">
              <v-card-title>Vaarojen selvittäminen</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="9">
                    <file-upload
                      ref="upload"
                      label="+ Lisää harjoitussuunnitelma.pdf"
                    ></file-upload>
                    <template v-for="(file, i) in digivihko.files">
                      <v-list-item-subtitle
                        :key="i"
                        class="text-wrap info--text d-flex align-center mb-3 cursor-pointer"
                        @click="openFile(file)"
                      >
                        <v-icon>mdi mdi-paperclip</v-icon>
                        <div>{{ file.filename }}</div>
                        <v-btn
                          :loading="loadingDelete"
                          icon
                          @click.stop="deleteFile(file)"
                        >
                          <v-icon color="error"> mdi mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-subtitle>
                    </template>
                  </v-col>
                  <v-col cols="12" lg="9">
                    <template v-for="(ep, i) in harjoitusCheckList">
                      <v-expand-transition :key="i">
                        <v-row
                          v-if="
                            !ep.dependsOn ||
                            (ep.dependsOn && isDependsOnDone(ep))
                          "
                        >
                          <v-col
                            :cols="ep.deepnessLevel"
                            v-if="ep.deepnessLevel > 0"
                          >
                          </v-col>
                          <v-col :cols="12 - ep.deepnessLevel">
                            <v-row>
                              <template v-if="ep.type === 'title'">
                                <v-col
                                  cols="12"
                                  class="align-center justify-space-between mt-5 mb-5"
                                >
                                  <span
                                    class="text-subtitle-1 font-weight-bold"
                                    >{{ ep.title }}</span
                                  >
                                </v-col>
                              </template>
                              <template v-else-if="ep.type === 'text'">
                                <v-col
                                  cols="12"
                                  class="align-center justify-space-between"
                                >
                                  <v-text-field
                                    :label="ep.title"
                                    v-model="ep.textValue"
                                    outlined
                                    dense
                                    hide-details
                                  ></v-text-field>
                                </v-col>
                              </template>
                              <template v-else-if="ep.type === 'switch'">
                                <v-col
                                  cols="12"
                                  class="align-center justify-space-between d-flex flex-row"
                                  :class="[
                                    !ep.done && ep.deepnessLevel === 0
                                      ? 'green lighten-5 green--text'
                                      : '',
                                    ep.done ? 'orange lighten-5' : '',
                                    ep.deepnessLevel === 0
                                      ? 'border-bottom'
                                      : '',
                                  ]"
                                >
                                  <div class="text-subtitle-1 d-flex">
                                    <v-icon v-if="ep.deepnessLevel > 0"
                                      >mdi mdi-arrow-right-bottom</v-icon
                                    ><span v-html="ep.title"></span>
                                    <span
                                      v-if="ep.required"
                                      class="ml-2 red--text"
                                      >*</span
                                    >
                                  </div>
                                  <div
                                    class="d-flex align-center justify-center"
                                  >
                                    <v-switch
                                      v-model="ep.done"
                                      @change="() => markChildrenDoneNull(ep)"
                                      outlined
                                      class="mt-0"
                                      dense
                                      hide-details
                                      color="warning"
                                    ></v-switch>
                                  </div>
                                </v-col>
                                <v-divider />
                              </template>
                              <template v-else>
                                <v-col
                                  cols="9"
                                  :class="[
                                    'd-flex align-center justify-space-between py-1',
                                    ep.done === null && ep.required === true
                                      ? 'red--text'
                                      : '',

                                    ep.done === 2 &&
                                      'green lighten-5 green--text',
                                    ep.done === 1 &&
                                      'orange lighten-5 orange--text',
                                    ep.done === 0 && 'red lighten-5 red--text',
                                    ep.deepnessLevel === 0
                                      ? 'border-bottom'
                                      : '',
                                  ]"
                                >
                                  <div class="text-subtitle-1">
                                    <v-icon v-if="ep.deepnessLevel > 0"
                                      >mdi mdi-arrow-right-bottom</v-icon
                                    >
                                    <span v-html="ep.title"></span>
                                    <v-icon
                                      v-if="ep.done === 2"
                                      class="green lighten-5 green--text"
                                      >mdi mdi-check</v-icon
                                    >
                                    <v-icon
                                      v-if="ep.done === 1"
                                      class="orange lighten-5 orange--text"
                                      >mdi mdi-exclamation-thick</v-icon
                                    >
                                    <v-icon
                                      v-if="ep.done === 0"
                                      class="red lighten-5 red--text"
                                      >mdi mdi-alert</v-icon
                                    >
                                    <span
                                      v-if="ep.required"
                                      class="ml-2 red--text"
                                      >*</span
                                    >
                                  </div>

                                  <span class="caption" v-if="ep.description">{{
                                    ep.description
                                  }}</span>
                                </v-col>
                                <v-col
                                  cols="3"
                                  :class="[
                                    'd-flex align-center py-1',
                                    ep.done === null && ep.required === true
                                      ? 'red--text'
                                      : '',
                                    ep.done === 2 &&
                                      'green lighten-5 green--text',
                                    ep.done === 1 &&
                                      'orange lighten-5 orange--text',
                                    ep.done === 0 && 'red lighten-5 red--text',
                                    ep.deepnessLevel === 0
                                      ? 'border-bottom'
                                      : '',
                                    'justify-end',
                                  ]"
                                >
                                  <v-btn
                                    icon
                                    @click="
                                      () =>
                                        levelKeysInclude(ep, 2)
                                          ? (ep.done = 2)
                                          : levelKeysInclude(ep, 1)
                                          ? (ep.done = 1)
                                          : levelKeysInclude(ep, 0)
                                          ? (ep.done = 0)
                                          : (ep.done = null)
                                    "
                                    v-if="
                                      ep.done === null &&
                                      levelKeysInclude(ep, 2)
                                    "
                                  >
                                    <v-icon>mdi mdi-check</v-icon>
                                  </v-btn>
                                  <v-btn
                                    icon
                                    @click="
                                      () =>
                                        levelKeysInclude(ep, 1)
                                          ? (ep.done = 1)
                                          : levelKeysInclude(ep, 0)
                                          ? (ep.done = 0)
                                          : (ep.done = null)
                                    "
                                    v-else-if="
                                      (levelKeysInclude(ep, 2) &&
                                        ep.done === 2) ||
                                      levelKeysInclude(ep, 1)
                                    "
                                  >
                                    <v-icon>mdi mdi-check</v-icon>
                                  </v-btn>
                                  <v-btn
                                    icon
                                    @click="
                                      () =>
                                        levelKeysInclude(ep, 0)
                                          ? (ep.done = 0)
                                          : (ep.done = null)
                                    "
                                    v-else-if="
                                      (levelKeysInclude(ep, 1) &&
                                        ep.done === 1) ||
                                      (levelKeysInclude(ep, 0) && ep.done !== 0)
                                    "
                                  >
                                    <v-icon>mdi mdi-check</v-icon>
                                  </v-btn>
                                  <v-btn
                                    icon
                                    @click="() => (ep.done = null)"
                                    v-else-if="ep.done === 0"
                                  >
                                    <v-icon>mdi mdi-close</v-icon>
                                  </v-btn>
                                </v-col>
                              </template>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-expand-transition>
                    </template>
                  </v-col>
                  <v-col cols="12" lg="9">
                    <v-textarea
                      outlined
                      dense
                      label="Huomiot turvallisuudessa / vaaroissa"
                      v-model="digivihko.checklistNote"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-2">
              <v-card-title>Osallistujat</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <osallistujat-table
                      :digivihko.sync="digivihko"
                      :loading="loadingOsallistujat"
                      :type="digivihko.type"
                    ></osallistujat-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <template v-if="digivihko.type === 'Hälytys'">
            <v-card class="mt-2">
              <v-card-text>
                <v-row>
                  <v-col cols="12" lg="3">
                    <v-text-field
                      v-model="digivihko.code"
                      hide-details
                      label="Tehtäväluokka"
                      outlined
                      persistent-placeholder
                      placeholder="401 B"
                    ></v-text-field>
                    <div v-if="tehtavaluokka" class="mt-2 text-subtitle-2">
                      <img
                        :src="require(`@/assets/tehtavaluokat.png`)"
                        height="16px"
                      />
                      {{ tehtavaluokka.label }}
                    </div>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      v-model="digivihko.address"
                      hide-details
                      label="Osoite"
                      outlined
                      persistent-placeholder
                      placeholder="Eva Alhströmin tie, Pori"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
            </v-card>
            <v-card class="mt-2">
              <v-card-title>Kesto</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" lg="3">
                    <modal-picker
                      v-model="digivihko.date"
                      :allowed-dates="allowedDates"
                      label="Lähtöpäivämäärä"
                    />
                  </v-col>
                  <v-col cols="8" lg="2">
                    <modal-picker
                      v-model="digivihko.time"
                      label="Lähtöaika"
                      type="time"
                    />
                  </v-col>
                  <v-col cols="4" lg="1"
                    ><v-btn icon class="mt-3" @click="syncParticipantStartTime"
                      ><v-icon>mdi mdi-sync</v-icon></v-btn
                    ></v-col
                  >
                </v-row>
                <v-row>
                  <v-col cols="12" lg="3">
                    <modal-picker
                      v-model="digivihko.dateReturn"
                      :allowed-dates="allowedDates2"
                      :error="diff < 0"
                      label="Paluupäivämäärä"
                    />
                  </v-col>
                  <v-col cols="8" lg="2">
                    <modal-picker
                      v-model="digivihko.timeReturn"
                      :error="diff < 0"
                      label="Paluuaika"
                      type="time"
                    />
                  </v-col>
                  <v-col cols="4" lg="1"
                    ><v-btn icon class="mt-3" @click="syncParticipantReturnTime"
                      ><v-icon>mdi mdi-sync</v-icon></v-btn
                    ></v-col
                  >
                  <v-col v-if="diff < 0" cols="12">
                    <v-alert type="error"
                      >Paluuajan pitää olla lähtöajan jälkeen
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-row>
                  <v-col
                    :class="[diff < 0 && 'error--text']"
                    class="text-center text-body-1"
                    cols="12"
                    lg="3"
                  >
                    {{ durationAsMinutes }} minuuttia

                    <br />
                    <v-row align="center" class="mt-1 mb-1">
                      <v-divider></v-divider>
                      <span class="ml-2 mr-2">tai</span>
                      <v-divider></v-divider>
                    </v-row>
                    {{ duration }}
                  </v-col>
                  <v-col cols="12" lg="1"> </v-col>
                  <v-col class="" cols="12" lg="5">
                    <v-checkbox
                      v-model="digivihko.longLasting"
                      label="Pitkäkestoinen? (yli 5h)"
                    />
                    <v-textarea
                      v-model="digivihko.longLastingDescription"
                      hide-details
                      label="Pitkäkestoisen selitys"
                      outlined
                      persistent-placeholder
                      placeholder="P3x nimi ja selvitys tehtävän pitkittymiselle"
                      rows="3"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-2">
              <v-card-text>
                <v-row>
                  <v-col cols="12" lg="9">
                    <v-textarea
                      v-model="digivihko.description"
                      auto-grow
                      hide-details
                      label="Kuvaus"
                      outlined
                      persistent-placeholder
                      placeholder="Valinnainen kuvaus"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-2">
              <v-card-title>Vahvuus</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <osallistujat-table
                      :digivihko.sync="digivihko"
                      :loading="loadingOsallistujat"
                      :type="digivihko.type"
                    ></osallistujat-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
            </v-card>
          </template>
          <v-card class="mt-2">
            <v-card-actions>
              <v-btn
                v-if="$id(digivihko)"
                :loading="loadingDelete"
                color="error"
                @click="deleteDigivihko"
                >Poista
              </v-btn>
              <v-spacer />

              <v-btn :loading="loadingSave" color="primary" @click="save"
                >Tallenna
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import api from "@/api";
import dayjs from "dayjs";
import ModalPicker from "@/components/ModalPicker";
import tehtavaluokat from "@/assets/tehtavaluokat";
import OsallistujatTable from "@/components/OsallistujatTable";
import FileUpload from "@/components/FileUpload.vue";
import { getBlob, ref } from "firebase/storage";
import { digivihotStorage } from "@/plugins/firebase.app";
import downloadjs from "downloadjs";

const findDeepnessLevel = (ep, arr, level = 0) => {
  if (!ep || !ep.dependsOn) return level + 1;
  return findDeepnessLevel(
    arr.find((a) => a.id === ep.dependsOn),
    arr,
    level + 1
  );
};
export default {
  name: "Digivihko",
  components: { FileUpload, OsallistujatTable, ModalPicker, BasicPage },
  data: () => {
    return {
      modalDate: false,
      tehtavaluokka: null,
      loadingDeleteFile: false,
      digivihko: {
        title: null,
        type: "Hälytys",
        address: null,
        code: null,
        participantCount: null,
        date: null,
        time: null,
        dateReturn: null,
        timeReturn: null,
        description: null,
        participants: [],
        longLasting: false,
        longLastingDescription: null,
        muuType: null,
        checklistNote: null,
        turvallisuusilmoitusSent: null,
      },
      muuTypes: [
        { label: "Valistus", value: "Valistus" },
        { label: "Varallaolo", value: "Varallaolo" },
      ],
      files: [],
      loading: false,
      loadingSave: false,
      loadingDelete: false,
      loadingOsallistujat: false,
      harjoitusCheckList: [
        {
          title:
            "<strong>1. Henkilökohtaisien suojavarusteiden tarkastus?</strong>",
          id: "personalProtection",
          done: null,
          levels: { 2: "OK", 0: "NOK" },
          required: true,
        },
        {
          title:
            "<strong>2. Sisältääkö harjoitus korkean riskin harjoitteita?</strong>",
          id: "highRisk",
          done: null,
          type: "switch",
          value: false,
          levels: { 2: "Ei", 1: "Kyllä" },
        },
        {
          title: "2.1 Sisältääkö harjoitus <strong>savusukellusta?</strong>",
          id: "smokeDiving",
          done: null,
          type: "switch",
          value: false,
          levels: { 2: "Ei", 1: "Kyllä" },
          dependsOn: "highRisk",
        },
        {
          title: "2.1.1 Onko harjoitussuunnitelma laadittu ja tallennettu?",
          id: "smokeDivingPlan",
          done: null,
          levels: { 2: "Kyllä", 0: "Ei" },
          dependsOn: "smokeDiving",
          required: true,
        },
        {
          title:
            "2.2 Sisältääkö harjoitus <strong>putoamisvaarallisella alueella työskentelyä?</strong>",
          id: "highWork",
          type: "switch",
          value: false,
          dependsOn: "highRisk",
          done: null,
        },
        {
          title: "2.2.1 Onko harjoitussuunnitelma laadittu ja tallennettu?",
          id: "highWorkPlan",
          done: null,
          levels: { 2: "Kyllä", 0: "Ei" },
          dependsOn: "highWork",
          required: true,
        },
        {
          title: "2.3 Sisältääkö harjoitus <strong>pintapelastusta?</strong>",
          id: "surfaceRescue",
          type: "switch",
          value: false,
          dependsOn: "highRisk",
          done: null,
        },
        {
          title: "2.3.1 Onko harjoitussuunnitelma laadittu ja tallennettu?",
          id: "surfaceRescuePlan",
          done: null,
          levels: { 2: "Kyllä", 0: "Ei" },
          dependsOn: "surfaceRescue",
          required: true,
        },

        {
          title:
            "2.4 Sisältääkö harjoitus <strong>kemikaalisukellusta?</strong>",
          id: "chemicalDiving",
          type: "switch",
          value: false,
          dependsOn: "highRisk",
          done: null,
        },
        {
          title: "2.4.1 Onko harjoitussuunnitelma laadittu ja tallennettu?",
          id: "chemicalDivingPlan",
          done: null,
          levels: { 2: "Kyllä", 0: "Ei" },
          dependsOn: "chemicalDiving",
          required: true,
        },

        {
          title:
            "2.5 Sisältääkö harjoitus <strong>moottorisahan käyttöä?</strong>",
          id: "chainsaw",
          type: "switch",
          value: false,
          dependsOn: "highRisk",
          done: null,
        },
        {
          title: "2.5.1 Onko harjoitussuunnitelma laadittu ja tallennettu?",
          id: "chainsawPlan",
          done: null,
          levels: { 2: "Kyllä", 0: "Ei" },
          dependsOn: "chainsaw",
          required: true,
        },
        {
          title:
            "2.6 Sisältääkö harjoitus <strong>työskentelyä tiealueella?</strong>",
          id: "roadwork",
          type: "switch",
          value: false,
          dependsOn: "highRisk",
          done: null,
        },
        {
          title: "2.6.1 Onko harjoitussuunnitelma laadittu ja tallennettu?",
          id: "roadworkPlan",
          done: null,
          levels: { 2: "Kyllä", 0: "Ei" },
          dependsOn: "roadwork",
          required: true,
        },
        {
          title: "<strong>3. Esiintyykö harjoituksessa altisteita?</strong>",
          id: "exposure",
          done: null,
          type: "switch",
          value: false,
        },
        {
          title: "3.1 Altistutaanko harjoituksessa <strong>melulle?</strong>",
          id: "exposureNoise",
          done: null,
          type: "switch",
          value: false,
          dependsOn: "exposure",
        },
        {
          title: "3.1.1 Kuulosuojaimien käytön ohjeistus?",
          id: "exposureNoiseInstructions",
          done: null,
          levels: { 2: "Ei", 0: "Kyllä" },
          dependsOn: "exposureNoise",
          required: true,
        },
        {
          title:
            "3.2 Altistutaanko harjoituksessa <strong>hengitysilman epäpuhtauksille?</strong>",
          id: "exposureAirImpurities",
          done: null,
          type: "switch",
          value: false,
          dependsOn: "exposure",
        },
        {
          title: "3.2.1 Suojaimien käytön ohjeistus?",
          id: "exposureAirImpuritiesInstructions",
          done: null,
          levels: { 2: "Ei", 0: "Kyllä" },
          dependsOn: "exposureAirImpurities",
          required: true,
        },

        {
          title:
            "3.3 Altistutaanko harjoituksessa <strong>mahdollisille silmiin kohdistuville roiskeille?</strong>",
          id: "exposureEyeSplash",
          done: null,
          type: "switch",
          value: false,
          dependsOn: "exposure",
        },
        {
          title: "3.3.1 Suojaimien käytön ohjeistus?",
          id: "exposureEyeSplashInstructions",
          done: null,
          levels: { 2: "Ei", 0: "Kyllä" },
          dependsOn: "exposureEyeSplash",
          required: true,
        },

        {
          title:
            "3.4 Altistutaanko harjoituksessa <strong>rajoitetussa näkyvyydessä työskentelyyn?</strong>",
          id: "exposureImpairedVisibility",
          done: null,
          type: "switch",
          value: false,
          dependsOn: "exposure",
        },
        {
          title: "3.4.1 Valaistuksen käytön ohjeistus?",
          id: "exposureImpairedVisibilityInstructions",
          done: null,
          levels: { 2: "Ei", 0: "Kyllä" },
          dependsOn: "exposureImpairedVisibility",
          required: true,
        },

        {
          title:
            "3.5 Altistutaanko harjoituksessa <strong>liikkumiseen liukkaalla alustalla?</strong>",
          id: "exposureSlipperySurface",
          done: null,
          type: "switch",
          value: false,
          dependsOn: "exposure",
        },
        {
          title: "3.5.1 Liukkauden torjunnan ohjeistus?",
          id: "exposureSlipperySurfaceInstructions",
          done: null,
          levels: { 2: "Ei", 0: "Kyllä" },
          dependsOn: "exposureSlipperySurface",
          required: true,
        },
        {
          title:
            "<strong>4. Käytiinkö keskustelu suojaimien käytön ja niiden mahdollisten puutteiden ilmoittamisen velvollisuudesta?</strong>",
          id: "discussionPersonalProtection",
          done: null,
          levels: { 2: "Kyllä", 0: "Ei" },
          required: true,
        },
        {
          title: "SA511 Ryhmänjohtajan nimi",
          id: "sa511yj",
          type: "text",
          done: null,
          textValue: "",
        },
        {
          title: "SA5112 Ryhmänjohtajan nimi",
          id: "sa5112yj",
          type: "text",
          done: null,
          textValue: "",
        },
        {
          title:
            "Harjoituksen kouluttaja, ilmoittaa yllä olevat tiedot oikeiksi:",
          id: "vastuukouluttaja",
          type: "text",
          done: null,
          textValue: "",
          required: true,
        },
      ].map((c, idx, arr) => {
        if (!c.levels) c.levels = { 2: "Ei", 1: "Kyllä", 0: "Ei tiedossa" };
        if (c.dependsOn) c.deepnessLevel = findDeepnessLevel(c, arr, 0);
        else c.deepnessLevel = 1;
        c.deepnessLevel = c.deepnessLevel - 1;
        return c;
      }),
    };
  },
  watch: {
    "digivihko.type"(to) {
      if (to === "Harjoitus") {
        if (this.digivihko.date === null && this.digivihko.time === null) {
          this.digivihko.date = dayjs().format("YYYY-MM-DD");
          this.digivihko.time = "18:00";
        }
        if (
          this.digivihko.dateReturn === null &&
          this.digivihko.timeReturn === null
        ) {
          this.digivihko.dateReturn = dayjs().format("YYYY-MM-DD");
          this.digivihko.timeReturn = "20:00";
        }

        try {
          const vastuukouluttajaIndex = this.harjoitusCheckList.findIndex(
            (c) => c.id === "vastuukouluttaja"
          );

          if (
            vastuukouluttajaIndex > -1 &&
            this.$user?.displayName &&
            this.harjoitusCheckList[vastuukouluttajaIndex].textValue === ""
          )
            this.harjoitusCheckList[vastuukouluttajaIndex].textValue =
              this.$user?.displayName || "";
        } catch (e) {
          console.error(e);
        }

        try {
          const harjoitukset = this.$store.state.harjoitukset;
          const now = dayjs();
          if (!this.digivihko.subject) {
            this.digivihko.subject =
              harjoitukset.find((h) => dayjs(h.date).isSame(now, "day"))
                ?.subject || null;
          }
        } catch (e) {
          console.error(e);
        }
      }
    },
    "digivihko.date"(to) {
      if (to != null && this.digivihko.dateReturn === null) {
        this.digivihko.dateReturn = to;
      }
      if (this.digivihko.type === "Harjoitus" && to != null) {
        this.digivihko.dateReturn = to;
      }
    },
    "digivihko.code"(to) {
      const t = to?.replace(/[a-zA-Z]+$/, "").trim();
      if (!t) {
        this.tehtavaluokka = null;
        return;
      }
      this.tehtavaluokka = this.tehtavaluokat.find((tl) => tl?.code === t);
    },
    /*"digivihko.dateReturn"(to) {
      if (
        this.digivihko.dateReturn &&
        this.digivihko.timeReturn &&
        dayjs(
          this.digivihko.dateReturn + " " + this.digivihko.timeReturn
        ).isValid()
      ) {
        this.digivihko.participants.forEach((p) => {
          if (!dayjs(p.dateReturn + " " + p.timeReturn).isValid()) {
            p.dateReturn = this.digivihko.dateReturn;
            p.timeReturn = this.digivihko.timeReturn;
          }
        });
      }
    },
    "digivihko.timeReturn"(to) {
      if (
        this.digivihko.dateReturn &&
        this.digivihko.timeReturn &&
        dayjs(
          this.digivihko.dateReturn + " " + this.digivihko.timeReturn
        ).isValid()
      ) {
        this.digivihko.participants.forEach((p) => {
          if (!dayjs(p.dateReturn + " " + p.timeReturn).isValid()) {
            p.dateReturn = this.digivihko.dateReturn;
            p.timeReturn = this.digivihko.timeReturn;
          }
        });
      }
    },*/
  },
  computed: {
    id() {
      return this.$id(this.digivihko);
    },
    tehtavaluokat() {
      return tehtavaluokat;
    },

    today() {
      return dayjs();
    },
    diff() {
      if (
        this.digivihko.date &&
        this.digivihko.time &&
        this.digivihko.dateReturn &&
        this.digivihko.timeReturn
      ) {
        const startTime = dayjs(
          this.digivihko.date + " " + this.digivihko.time,
          "YYYY-MM-DD HH:mm"
        );
        const returnTime = dayjs(
          this.digivihko.dateReturn + " " + this.digivihko.timeReturn,
          "YYYY-MM-DD HH:mm"
        );
        return returnTime.diff(startTime);
      }
      return null;
    },
    duration() {
      if (this.diff) {
        const dur = dayjs.duration(this.diff);
        let f = dur.format("H[h] m[m]");
        if (dur.as("days") > 1) {
          f = dur.format("D[d] H[h] m[m]");
        }
        return f;
      }
      return null;
    },
    durationAsMinutes() {
      if (this.diff) {
        const dur = dayjs.duration(this.diff);
        return dur.as("minutes");
      }
      return null;
    },
  },
  methods: {
    async openFile(file) {
      try {
        const theBlob = await getBlob(ref(digivihotStorage, file.key));
        downloadjs(theBlob, file.filename, file.fileType);
      } catch (error) {
        console.error(error);
        this.$toast("Tiedostoa ei voitu avata. Yritä uudelleen.", {
          type: "error",
        });
      }
    },
    async deleteFile(file) {
      this.loadingDeleteFile = true;
      try {
        await api.digivihkoDeleteFile(file);
        this.digivihko.files = this.digivihko.files.filter(
          (f) => f.key !== file.key
        );
        this.$toast("Tiedosto poistettu", {
          type: "success",
        });
      } catch (e) {
        console.error(e);
        this.$toast("Tiedoston poistaminen epäonnistui", {
          type: "error",
        });
      }
      this.loadingDelete = false;
    },
    markChildrenDoneNull(ep) {
      if (ep.done === false || ep.done === null) {
        this.harjoitusCheckList.forEach((hc) => {
          if (hc.dependsOn === ep.id) {
            hc.done = hc.type === "switch" ? false : null;
            this.markChildrenDoneNull(hc);
          }
        });
      }
    },
    levelKeysInclude(ep, value) {
      return Object.keys(ep.levels)
        .map((k) => String(k))
        .includes(String(value));
    },
    isDependsOnDone(ep) {
      const dependentOn = this.harjoitusCheckList.find(
        (hc) => hc.id === ep.dependsOn
      );
      return dependentOn?.done || dependentOn?.value;
    },
    checkMuuTypeRequired() {
      if (this.digivihko.type === "Muu") {
        if (this.digivihko.muuType == null) {
          this.$toast("Tyyppi pitää olla valittu", { type: "error" });
          return false;
        }
      }
      return true;
    },
    checkForMissingSeats() {
      const missing = { 511: [], 5112: [] };
      if (this.digivihko.participants) {
        const participants511 = this.digivihko.participants.filter(
          (p) => p.engine === 1
        ); // RSA511
        const participants5112 = this.digivihko.participants.filter(
          (p) => p.engine === 2
        ); // RSA5112
        const checkFor = ["yj", "kone", "s1", "s2", "s3", "s4"];

        checkFor.forEach((value, index) => {
          if (participants511.length > index) {
            if (participants511.findIndex((p) => p[value] === true) <= -1) {
              missing["511"].push(value);
            }
          }
          if (participants5112.length > index) {
            if (participants5112.findIndex((p) => p[value] === true) <= -1) {
              missing["5112"].push(value);
            }
          }
        });
      }
      return missing;
    },
    allowedDates(val) {
      return this.today.isSameOrAfter(val, "day");
    },
    allowedDates2(val) {
      if (!this.digivihko.date) return this.today.isSameOrAfter(val, "day");
      return (
        dayjs(val).isSameOrAfter(this.digivihko.date) &&
        this.today.isSameOrAfter(val, "day")
      );
    },
    async syncParticipantStartTime() {
      if (
        await this.$confirm(
          "Haluatko varmasti synkronoida kaikkien osallistujien lähtöajan?",
          {
            title: "Synkronoi lähtöajat",
            buttonTrueText: "Synkronoi",
            buttonFalseText: "Peruuta",
          }
        )
      ) {
        this.digivihko.participants.forEach((p) => {
          p.date = this.digivihko.date;
          p.time = this.digivihko.time;
        });
      }
    },
    async syncParticipantReturnTime() {
      if (
        await this.$confirm(
          "Haluatko varmasti synkronoida kaikkien osallistujien paluuajan?",
          {
            title: "Synkronoi paluuajat",
            buttonTrueText: "Synkronoi",
            buttonFalseText: "Peruuta",
          }
        )
      ) {
        this.digivihko.participants.forEach((p) => {
          p.dateReturn = this.digivihko.dateReturn;
          p.timeReturn = this.digivihko.timeReturn;
        });
      }
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.$toast("Täytä kaikki pakolliset kentät", { type: "error" });
        return;
      }

      if (this.digivihko.participants) {
        const faulty = this.digivihko.participants.filter((p) => {
          if (
            dayjs(p.date + " " + p.time).isAfter(
              dayjs(p.dateReturn + " " + p.timeReturn)
            )
          ) {
            return true;
          }
        });
        if (faulty.length > 0) {
          this.$toast(
            "Henkilöiden " +
              faulty.map((f) => f.name).join(", ") +
              " paluuaika ei voi olla aiemmin kuin lähtöaika",
            {
              type: "error",
              timeout: 10000,
            }
          );
          return;
        }
        const faulty2 = this.digivihko.participants.filter((p) => {
          if (!dayjs(p.dateReturn + " " + p.timeReturn).isValid()) {
            return true;
          }
          if (!dayjs(p.date + " " + p.time).isValid()) {
            return true;
          }
        });
        if (faulty2.length > 0) {
          this.$toast(
            "Henkilöiden " +
              faulty2.map((f) => f.name).join(", ") +
              " lähtö- tai paluuaika ei ole oikein. Tarkista päivämäärät ja kellonajat.",
            {
              type: "error",
              timeout: 10000,
            }
          );
          return;
        }
      }

      if (this.digivihko.type === "Harjoitus") {
        let cont = true; // short for continue
        this.harjoitusCheckList
          .filter((h) => h.required)
          .forEach((h) => {
            if (
              (h.deepnessLevel === 0 && h.done === null && !h.textValue) ||
              (h.deepnessLevel === 2 &&
                h.done === null &&
                this.harjoitusCheckList.find(
                  (a) => a.id === h.dependsOn && a.done
                ))
            ) {
              this.$toast(
                `Täytä vaarojen selvittäminen - kohta:
${h.title?.replaceAll("<strong>", "").replaceAll("</strong>", "")}`,
                {
                  timeout: 10000,
                  type: "error",
                }
              );
              cont = false;
              return true;
            }
            return false;
          });
        if (!cont) return;
        const turvallisuusilmoitusTexts = [];
        this.harjoitusCheckList
          .filter((h) => h.required)
          .forEach((h) => {
            if (
              (h.deepnessLevel === 0 && h.done === 0 && !h.textValue) ||
              (h.deepnessLevel === 2 && h.done === 0 && !h.type)
            ) {
              turvallisuusilmoitusTexts.push(h.title);
            }
            return false;
          });
        if (
          !this.digivihko.turvallisuusilmoitusSent &&
          turvallisuusilmoitusTexts.length > 0
        ) {
          if (
            await this.$confirm(
              "Harjoituksesta tehdään <strong>turvallisuusilmoitus</strong> automaattisesti, koska seuraavat kohdat on merkattu punaisella: <br/><br/>" +
                turvallisuusilmoitusTexts
                  .map((t) => `<strong>${t}</strong>`)
                  .join(" \n<br/>") +
                "<br/><br/>Lähetetäänkö turvallisuusilmoitus ja tallennetaan digivihko?<br/>Mikäli painat peruuta, digivihkoa ei tallenneta.",
              {
                buttonTrueText: "Jatka",
                buttonFalseText: "Peruuta",
                color: "error",
                title: "Turvallisuusilmoitus",
              }
            )
          ) {
            // Send turvallisuusilmoitus
            try {
              let kouluttaja = null;
              const vastuukouluttajaIndex = this.harjoitusCheckList.findIndex(
                (c) => c.id === "vastuukouluttaja"
              );

              if (vastuukouluttajaIndex > -1) {
                kouluttaja =
                  this.harjoitusCheckList[vastuukouluttajaIndex].textValue;
              }
              const turvallisuusilmoitus = {
                date: this.digivihko.date,
                time: "",
                location: "",
                description: `
Tämä on automaattinen turvallisuusilmoitus harjoituksen täyttämisestä Noormarkun VPK Appissa: <br/>
Tämä ilmoitus on lähetetty automaattisesti, koska harjoituksen vaarojen selvittäminen-kohtia on merkattu punaisella.<br /><br/>
Harjoituksen aihe: ${this.digivihko.subject}<br/>
Harjoituksen kuvaus: ${this.digivihko.description}<br/>
Harjoituksen kouluttaja: ${kouluttaja}<br/>
<br/><br/>
Punaisella merkatut:
<br/>
${turvallisuusilmoitusTexts
  .map((c) => c?.replaceAll("<strong>", "").replaceAll("</strong>", ""))
  .join("<br/>")}
<br/><br/>
Ilmoituksen lähettäjä: ${this.$user?.displayName}
`,
                files: [],
                consequences: [],
                type: "Harjoitusvaara",
              };
              await api.vaaratilannehavaintoSend(turvallisuusilmoitus);
              this.digivihko.turvallisuusilmoitusSent = dayjs().toISOString();
            } catch (e) {
              console.error("Error sending turvallisuusilmoitus", e);
            }
          } else {
            this.$toast("Digivihkoa EI TALLENNETTU!", {
              type: "error",
            });
            return;
          }
        }
      }
      if (this.digivihko.type === "Hälytys") {
        // Hälytyksessä tarkastetaan että 511 ja 5112 istumapaikat on merkattu
        const missingSeats = this.checkForMissingSeats();
        let seatsError = false;
        if (missingSeats["5112"].length > 0) {
          this.$toast(
            "RSA5112 puuttuu " +
              new Intl.ListFormat("fi").format(missingSeats["5112"]),
            {
              timeout: 10000,
              type: "error",
            }
          );
          seatsError = true;
        }
        if (missingSeats["511"].length > 0) {
          this.$toast(
            "RSA511 puuttuu " +
              new Intl.ListFormat("fi").format(missingSeats["511"]),
            {
              timeout: 10000,
              type: "error",
            }
          );
          seatsError = true;
        }

        if (seatsError) {
          return;
        }
      }
      if (!this.checkMuuTypeRequired()) {
        return;
      }
      this.loadingSave = true;

      let data = { ...this.digivihko };
      if (this.digivihko.type === "Harjoitus") {
        data = DigivihkoHarjoitus.from({
          ...data,
          checklist: this.harjoitusCheckList,
        });
      } else if (this.digivihko.type === "Muu") {
        data = DigivihkoMuu.from(data);
      }
      const files = await this.$refs?.upload?.getFiles();

      try {
        const digivihko = (await api.digivihkoUpsert(data)).data;

        if (files) {
          for (let filesKey in files) {
            (
              await api.digivihkoAddFile({
                digivihkoId: digivihko.id,
                ...files[filesKey],
              })
            ).data;
          }
          this.$refs.upload?.reset();
        }

        this.$toast("Digivihko tallennettu.", {
          type: "success",
        });
        if (!this.id) {
          this.$router.push({
            name: "DigivihkoById",
            params: { id: this.$id(digivihko) },
          });
        }
        this.digivihko = digivihko;

        await this.reloadDigivihko();
      } catch (e) {
        this.$toast("Tallennus epäonnistui. Ole hyvä ja yritä uudelleen.", {
          type: "error",
        });
        console.error(e);
      }
      this.loadingSave = false;
    },
    async reloadDigivihko() {
      this.loading = true;
      const digivihko = (await api.digivihkoGet({ id: this.$route.params.id }))
        .data;
      if (!digivihko.participants) digivihko.participants = [];

      if (digivihko.checklist) {
        this.harjoitusCheckList = digivihko.checklist;
      }
      this.digivihko = digivihko;
      this.loading = false;
    },
    async deleteDigivihko() {
      const doDelete = await this.$deleteConfirm("Poistetaanko digivihko?");
      if (!doDelete) return false;
      this.loadingDelete = true;
      try {
        await api.digivihkoDelete(this.digivihko);
        this.$toast("Digivihko poistettu", {
          type: "success",
        });
        this.$router.push({ name: "Digivihot" });
      } catch (e) {
        console.error(e);
        this.$toast("Poistaminen epäonnistui", {
          type: "error",
        });
      }
      this.loadingDelete = false;
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.reloadDigivihko();
    } else if (this.$route.params.type === "Hälytys") {
      this.digivihko.type = "Hälytys";
    } else if (this.$route.params.type === "Harjoitus") {
      this.digivihko.type = "Harjoitus";
    } else if (this.$route.params.type === "Muu") {
      this.digivihko.type = "Muu";
    }

    window.checkMissing = this.checkForMissingSeats;
  },
};

class DigivihkoHarjoitus {
  constructor(
    id,
    type,
    subject,
    date,
    time,
    dateReturn,
    timeReturn,
    description,
    participants,
    checklist,
    checklistNote,
    turvallisuusilmoitusSent
  ) {
    this.id = id;
    this.type = type;
    this.subject = subject;
    this.date = date;
    this.time = time;
    this.dateReturn = dateReturn;
    this.timeReturn = timeReturn;
    this.description = description;
    this.participants = participants;
    this.checklist = checklist;
    this.checklistNote = checklistNote;
    this.turvallisuusilmoitusSent = turvallisuusilmoitusSent;
  }

  static from(obj) {
    const participants = obj.participants?.map((p) =>
      DigivihkoHarjoitusParticipant.from(p)
    );
    return new DigivihkoHarjoitus(
      obj.id,
      obj.type,
      obj.subject,
      obj.date,
      obj.time,
      obj.dateReturn,
      obj.timeReturn,
      obj.description,
      participants,
      obj.checklist,
      obj.checklistNote,
      obj.turvallisuusilmoitusSent
    );
  }
}

class DigivihkoHarjoitusParticipant {
  constructor(id, date, time, dateReturn, timeReturn, name, henkiloId) {
    this.id = id;
    this.date = date;
    this.time = time;
    this.dateReturn = dateReturn;
    this.timeReturn = timeReturn;
    this.name = name;
    this.henkiloId = henkiloId;
  }

  static from(obj) {
    return new DigivihkoHarjoitusParticipant(
      obj.id,
      obj.date,
      obj.time,
      obj.dateReturn,
      obj.timeReturn,
      obj.name,
      obj.henkiloId
    );
  }
}

class DigivihkoMuu {
  constructor(
    id,
    type,
    subject,
    muuType,
    date,
    time,
    dateReturn,
    timeReturn,
    description,
    participants
  ) {
    this.id = id;
    this.type = type;
    this.subject = subject;
    this.muuType = muuType;
    this.date = date;
    this.time = time;
    this.dateReturn = dateReturn;
    this.timeReturn = timeReturn;
    this.description = description;
    this.participants = participants;
  }

  static from(obj) {
    const participants = obj.participants?.map((p) =>
      DigivihkoMuuParticipant.from(p)
    );
    return new DigivihkoMuu(
      obj.id,
      obj.type,
      obj.subject,
      obj.muuType,
      obj.date,
      obj.time,
      obj.dateReturn,
      obj.timeReturn,
      obj.description,
      participants
    );
  }
}

class DigivihkoMuuParticipant {
  constructor(
    id,
    date,
    time,
    dateReturn,
    timeReturn,
    name,
    isYj,
    yj,
    henkiloId
  ) {
    this.id = id;
    this.date = date;
    this.time = time;
    this.dateReturn = dateReturn;
    this.timeReturn = timeReturn;
    this.name = name;
    this.isYj = isYj;
    this.yj = yj;
    this.henkiloId = henkiloId;
  }

  static from(obj) {
    return new DigivihkoMuuParticipant(
      obj.id,
      obj.date,
      obj.time,
      obj.dateReturn,
      obj.timeReturn,
      obj.name,
      obj.isYj,
      obj.yj,
      obj.henkiloId
    );
  }
}
</script>

<style lang="scss" scoped>
.border-bottom {
  border-bottom: 1px solid #cccccc !important;
  border-top: 1px solid #cccccc !important;
}
</style>
