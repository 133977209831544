<script src="../plugins/globals.js"></script>
<template>
  <basic-page title="Ilmoitustaulu">
    <v-row class="fill-height">
      <v-col cols="12" lg="8" offset-lg="2" offset-xl="2" xl="8">
        <v-row class="mb-5">
          <v-col v-if="nextHarjoitus" cols="12" md="6">
            <v-card>
              <v-list-item>
                <v-list-item-content>
                  <div class="text-overline mb-4">Harjoitukset</div>
                  <v-list-item-subtitle
                    class="text-h6 mb-1 black--text text-wrap"
                    >{{ nextHarjoitus.subject }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-wrap"
                    ><span
                      >{{
                        $formatWeekDayDateAndHourMinute(nextHarjoitus.date)
                      }}
                      {{ formatType(nextHarjoitus.type) }},
                      {{ nextHarjoitus.location }},
                      {{ nextHarjoitus.info }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar>
                  <v-icon>mdi mdi-fireplace</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col v-if="nextEvyHarjoitus" cols="12" md="6">
            <v-card>
              <v-list-item>
                <v-list-item-content>
                  <div class="text-overline mb-4">Evy</div>
                  <v-list-item-subtitle
                    class="text-h6 mb-1 black--text text-wrap"
                    >{{ nextEvyHarjoitus.subject }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-wrap"
                    ><span
                      >{{
                        $formatWeekDayDateAndHourMinute(nextEvyHarjoitus.date)
                      }}
                      {{ formatType(nextEvyHarjoitus.type) }},
                      {{ nextEvyHarjoitus.location }},
                      {{ nextEvyHarjoitus.info }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar>
                  <v-icon>mdi mdi-hospital-box</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>

        <v-card>
          <v-card-text>
            <span class="subtitle-1"
              >Tänään on viikko {{ week }}, {{ today }}</span
            >
          </v-card-text>
          <v-divider />

          <v-card-text>
            <v-row class="fill-height">
              <v-col>
                <v-sheet height="64">
                  <v-toolbar flat>
                    <v-btn
                      class="mr-4"
                      color="grey darken-2"
                      outlined
                      @click="setToday"
                    >
                      Tänään
                    </v-btn>
                    <v-btn color="grey darken-2" fab small text @click="prev">
                      <v-icon small> mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn color="grey darken-2" fab small text @click="next">
                      <v-icon small> mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendar">
                      {{ $formatMonthYear($refs.calendar.value || Date.now()) }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu bottom right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          color="grey darken-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span>{{ typeToLabel[calendarType] }}</span>
                          <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="calendarType = 'day'">
                          <v-list-item-title>Päivä</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="calendarType = 'week'">
                          <v-list-item-title>Viikko</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="calendarType = 'month'">
                          <v-list-item-title>Kuukausi</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                  <v-calendar
                    ref="calendar"
                    v-model="focus"
                    :events="filteredEvents"
                    :weekdays="weekdays"
                    color="primary"
                    :type="calendarType"
                    @click:event="showEvent"
                  ></v-calendar>
                  <v-menu
                    v-model="selectedOpen"
                    :activator="selectedElement"
                    :close-on-content-click="false"
                    offset-x
                  >
                    <v-card color="grey lighten-4" flat min-width="250px">
                      <v-toolbar :color="selectedEvent.color" dark>
                        <span>{{ selectedEvent.name }} </span>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <v-card-text>
                        <div v-if="selectedEvent.dateStart">
                          <span class="text-subtitle-2">
                            {{
                              $capitalFirstLetter(
                                $formatWeekDay(
                                  selectedEvent.dateStart +
                                    " " +
                                    (selectedEvent.timeStart || "00:00:00")
                                )
                              )
                            }}
                            {{
                              $formatDateAndHourMinute(
                                selectedEvent.dateStart +
                                  " " +
                                  (selectedEvent.timeStart || "00:00:00")
                              )
                            }}</span
                          >

                          <span
                            v-if="selectedEvent.dateEnd"
                            class="text-subtitle-2"
                          >
                            -
                            {{
                              $capitalFirstLetter(
                                $formatWeekDay(
                                  selectedEvent.dateEnd +
                                    " " +
                                    (selectedEvent.timeEnd || "00:00:00")
                                )
                              )
                            }}
                            {{
                              $formatDateAndHourMinute(
                                selectedEvent.dateEnd +
                                  " " +
                                  (selectedEvent.timeEnd || "00:00:00")
                              )
                            }}</span
                          >
                        </div>
                      </v-card-text>
                      <v-card-text>
                        <span v-html="selectedEvent.details"></span>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          color="secondary"
                          text
                          @click="selectedOpen = false"
                        >
                          Sulje
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title class="mt-5"
            >Ilmoitustaulu
            <v-spacer />
            <div v-if="$isAdmin || $isNaisosasto">
              <v-btn color="primary" rounded @click="createIlmoitus" small>
                <v-icon>mdi mdi-plus</v-icon> Uusi ilmoitus
              </v-btn>
            </div>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-subtitle>
            <!-- Buttons for filtering filteredIlmoitukset type property kokous, ilmoitus, ilmoittautuminen -->
            <v-row no-gutters justify="start" align-content="center">
              <v-icon class="mr-2">mdi mdi-filter</v-icon>
              <v-btn-toggle v-model="eventFilter" multiple>
                <v-btn value="ilmoitus" small>
                  <template v-if="$vuetify.breakpoint.mdAndUp"
                    >Ilmoitukset</template
                  >
                  <template v-else>
                    <v-icon>mdi mdi-exclamation-thick</v-icon>
                  </template>
                </v-btn>
                <v-btn value="ilmoittautuminen" small>
                  <template v-if="$vuetify.breakpoint.mdAndUp"
                    >Ilmoittautumiset</template
                  >
                  <template v-else>
                    <v-icon>mdi mdi-account-multiple</v-icon>
                  </template>
                </v-btn>
              </v-btn-toggle>

              <v-spacer />
              <v-switch
                v-model="showOld"
                class="ml-5 mt-n1"
                dense
                hide-details
                inset
                prepend-icon="mdi mdi-eye"
                title="Näytä vanhat ilmoitukset"
              >
              </v-switch>
            </v-row>
          </v-card-subtitle>
          <v-divider />
          <v-card-text v-if="loading">
            <v-skeleton-loader
              type="list-item-three-line,list-item-three-line,list-item-three-line,list-item-three-line,list-item-three-line"
              width="100%"
            />
          </v-card-text>
        </v-card>

        <template v-if="!loading">
          <template v-for="ilmoitus in filteredIlmoitukset">
            <ilmoitus-list-item
              :key="ilmoitus.id"
              :ilmoitus="ilmoitus"
              @reload="reloadIlmoitukset"
            />
            <!--<v-divider :key="`${i}_2`" class="mb-4 mt-2" />-->
          </template>
        </template>

        <!--<v-card
          class="mt-5 mb-5"
          v-if="isNotificationsSupported && filteredIlmoitukset.length > 0"
        >
          <v-card-text>
            <v-btn @click="() => askForNotifications()"
              >Pyydä ilmoituksia</v-btn
            >
            <ul class="mt-5">
              <template v-for="(permissionMessage, i) in permissionMessages">
                <li :key="i">{{ permissionMessage }}</li>
              </template>
            </ul>
          </v-card-text>
        </v-card>-->
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import dayjs from "dayjs";
import Ilmoittautuminen from "@/components/Ilmoitustaulu/Ilmoittautuminen";
import IlmoitusListItem from "@/components/Ilmoitustaulu/IlmoitusListItem";
import api from "@/api";
import { orderBy } from "lodash";
import app, { getToken } from "@/plugins/firebase.app";
import { getMessaging } from "firebase/messaging";

export default {
  name: "Ilmoitustaulu",
  components: { Ilmoittautuminen, BasicPage, IlmoitusListItem },
  data() {
    return {
      ilmoitukset: [],
      hallitusIlmoitukset: [],
      loading: false,
      focus: "",
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      permissionMessages: [],
      eventFilter: [],
      showOld: false,
      typeToLabel: {
        month: "Kuukausi",
        week: "Viikko",
        day: "Päivä",
      },
      isNotificationsSupported: "Notification" in window,
      calendarType: "month",
    };
  },
  computed: {
    filteredIlmoitukset() {
      // eslint-disable-next-line no-undef
      let ilmoitukset = structuredClone(this.ilmoitukset);
      ilmoitukset.forEach((i) => {
        i._date = [i.dateStart, i.timeEnd, i.createdAt, i.created]
          .filter(Boolean)
          .join(" ");
      });
      if (this.eventFilter.length > 0) {
        ilmoitukset = ilmoitukset.filter((ilmoitus) =>
          this.eventFilter.includes(ilmoitus.type)
        );
      }
      if (!this.showOld) {
        ilmoitukset = ilmoitukset.filter((ilmoitus) => {
          try {
            if (ilmoitus.dateEnd) {
              return dayjs(
                this.$parseDateAsHelsinki(
                  `${ilmoitus.dateEnd} ${ilmoitus.timeEnd || "23:59:59"}`
                )
              ).isAfter(dayjs());
            } else if (ilmoitus.dateStart) {
              return dayjs(
                this.$parseDateAsHelsinki(
                  `${ilmoitus.dateStart} ${ilmoitus.timeStart || "00:00:00"}`
                )
              ).isAfter(dayjs());
            }
          } catch (e) {
            console.error(e);
            return true;
          }
          return true;
        });
      }
      return orderBy(ilmoitukset, ["_date"], ["asc"]);
    },
    filteredEvents() {
      // eslint-disable-next-line no-undef
      let ilmoitukset = structuredClone(this.ilmoitukset);
      ilmoitukset.forEach((i) => {
        i._date = [i.dateStart, i.timeEnd, i.createdAt, i.created]
          .filter(Boolean)
          .join(" ");
      });
      let hallitusIlmoitukset = structuredClone(this.hallitusIlmoitukset);
      hallitusIlmoitukset.forEach((i) => {
        i._date = [i.dateStart, i.timeEnd, i.createdAt, i.created]
          .filter(Boolean)
          .join(" ");
      });
      return [
        ilmoitukset
          ?.filter((e) => e.dateStart)
          .map((e) => {
            return {
              ...e,
              name: e.title,
              start: dayjs(
                e.dateStart + " " + (e.timeStart || "00:00:00")
              ).toDate(),
              end: dayjs(
                e.dateEnd
                  ? e.dateEnd + " " + (e.timeEnd || "00:00:00")
                  : e.dateStart + " " + (e.timeStart || "00:00:00")
              ).toDate(),
              timed: true,
              details: e.content,
              type: "hallitus",
            };
          }) || [],

        hallitusIlmoitukset
          ?.filter((e) => e.dateStart)
          .map((e) => {
            return {
              ...e,
              name: "Hallitus: " + e.title,
              start: dayjs(
                e.dateStart + " " + (e.timeStart || "00:00:00")
              ).toDate(),
              end: dayjs(
                e.dateEnd
                  ? e.dateEnd + " " + (e.timeEnd || "00:00:00")
                  : e.dateStart + " " + (e.timeStart || "00:00:00")
              ).toDate(),
              timed: true,
              details: `Hallituksen ${e.type}`,
              type: "hallitusIlmoitus",
              color: "grey",
            };
          }) || [],
        this.harjoitukset.map((e) => {
          return {
            ...e,
            name: e.subject,
            start: dayjs(e.date).toDate(),
            timed: true,
            type: "harjoitus",
            color: "info",
            details: [
              `<strong>${this.$formatDateAndHourMinute(e.date)}</strong>`,
              `Aihe: <strong>${e.subject}</strong>`,
              `Paikka: <strong>${e.location}</strong>`,
              `Tyyppi: <strong>${e.type}</strong>`,
              `Kouluttaja: <strong>${e.info}</strong>`,
            ].join("</br> "),
          };
        }) || [],
        this.evyharjoitukset.map((e) => {
          return {
            ...e,
            name: e.subject,
            start: dayjs(e.date).toDate(),
            timed: true,
            type: "evy",
            color: "error",
            details: [
              `<strong>${this.$formatDateAndHourMinute(e.date)}</strong>`,
              `Aihe: <strong>${e.subject}</strong>`,
              `Paikka: <strong>${e.location}</strong>`,
              `Tyyppi: <strong>${e.type}</strong>`,
              `Kouluttaja: <strong>${e.info}</strong>`,
            ].join("</br> "),
          };
        }) || [],
      ].flatMap((e) => e);
    },
    today() {
      return dayjs().format("LLLL");
    },
    week() {
      return dayjs().format("w");
    },
    harjoitukset() {
      if (this.$isOnlyNaisosasto) return [];
      return this.$store.state.harjoitukset;
    },
    evyharjoitukset() {
      if (this.$isOnlyNaisosasto) return [];
      return this.$store.state.evyharjoitukset;
    },
    nextHarjoitus() {
      const today = dayjs();
      const nextHarjoitus = this.harjoitukset.find(
        (h) => h && dayjs(h.date).isAfter(today)
      );
      return nextHarjoitus;
    },
    nextEvyHarjoitus() {
      const today = dayjs();
      const nextEvyHarjoitus = this.evyharjoitukset.find(
        (h) => h && dayjs(h.date).isAfter(today)
      );
      return nextEvyHarjoitus;
    },
  },
  methods: {
    askForNotifications() {
      const messaging = getMessaging(app);
      (async () => {
        this.permissionMessages = [
          ...this.permissionMessages,
          `Oikeudet: ${Notification.permission}`,
        ];
        console.log("Notification.permission =", Notification.permission);
        if (localStorage.getItem("notificationPermissionDenied") === "true") {
          this.permissionMessages = [
            ...this.permissionMessages,
            `Käyttäjä on estänyt ilmoitukset`,
          ];
          return;
        }
        this.permissionMessages = [
          ...this.permissionMessages,
          `Pyydetään oikeuksia`,
        ];
        Notification.requestPermission()
          .then(async (permission) => {
            if (permission === "granted") {
              this.permissionMessages = [
                ...this.permissionMessages,
                `Oikeudet pyydetty. Tulos: ${permission}`,
              ];
              const serviceWorkerRegistration =
                await navigator.serviceWorker.getRegistration();
              return getToken(messaging, {
                vapidKey:
                  "BCZ3S_HG6b-bUNUJkxygFPTu_UDHfthcEcFmCODFxCTVNNVNRbZtRhYWcNnK7DMkTWAfLEopWOynqpOb_pcJyi0",
                serviceWorkerRegistration: serviceWorkerRegistration,
              });
            } else if (permission === "denied") {
              console.log(
                "DENIED! Unable to get permission to notify.",
                permission
              );
              this.permissionMessages = [
                ...this.permissionMessages,
                `Oikeudet pyydetty. Tulos: ${permission}`,
              ];
              throw Error("Unable to get permission to notify.");
            } else {
              this.permissionMessages = [
                ...this.permissionMessages,
                `Oikeudet pyydetty. Tulos: ${permission}`,
              ];
              console.log("Unable to get permission to notify.", permission);
              throw Error("Unable to get permission to notify.");
            }
          })
          .then(async (token) => {
            if (token) {
              console.log("Firebase Messaging Token:", token);
              this.permissionMessages = [
                ...this.permissionMessages,
                `Token saatu: ${token}`,
              ];
              try {
                await api.firebaseMessagingAddToken({
                  token,
                  userAgent: navigator.userAgent,
                });
                this.permissionMessages = [
                  ...this.permissionMessages,
                  `Token lähetetty tietokantaan: ${token}`,
                ];
                this.permissionMessages = [
                  ...this.permissionMessages,
                  `Ilmoitukset otettu käyttöön onnistuneesti.`,
                ];
              } catch (e) {
                console.error("firebaseMessagingAddToken error", e);
                this.permissionMessages = [
                  ...this.permissionMessages,
                  `Tokenia ei saatu! ${e}`,
                ];
              }
            } else {
              console.log("No token received");
              this.permissionMessages = [
                ...this.permissionMessages,
                `Tokenia ei saatu! Virhe ei tiedossa.`,
              ];
            }

            // Send this token to your server for future use
          })
          .catch((err) => {
            console.log("Unable to get permission to notify.", err);
            this.permissionMessages = [
              ...this.permissionMessages,
              `Tokenia ei saatu! Catch block: ${err}`,
            ];
          });
      })();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    setToday() {
      this.focus = "";
    },

    async reloadHarjoitukset() {
      this.$store.dispatch("loadHarjoitukset");
    },
    async reloadIlmoitukset() {
      if (this.ilmoitukset.length === 0) {
        this.loading = true;
      }
      try {
        const ilmoitukset = (await api.ilmoituksetList()).data;

        ilmoitukset.forEach((ilmoitus) => {
          if (
            ilmoitus.participants?.findIndex(
              (il) => il.sub === this.$user.uid
            ) > -1
          ) {
            ilmoitus._participant = true;
          }
        });
        this.ilmoitukset = ilmoitukset;
      } catch (e) {
        console.error(e);
        this.$toast("Ilmoitusten haku epäonnistui.", {
          type: "error",
        });
      }
      this.loading = false;
    },
    async reloadHallitusIlmoitukset() {
      try {
        const ilmoitukset = (await api.ilmoituksetHallitusListPublished()).data;
        this.hallitusIlmoitukset = ilmoitukset;
      } catch (e) {
        console.error(e);
        this.$toast("Hallituksen ilmoitusten haku epäonnistui.", {
          type: "error",
        });
      }
    },
    reloadAll() {
      this.reloadHarjoitukset();
      this.reloadIlmoitukset();
      this.reloadHallitusIlmoitukset();
    },
    formatType(type) {
      return type === "OT" ? "Oppitunti" : type === "HT" ? "Harjoitus" : "";
    },
    createIlmoitus() {
      this.$router.push({ name: "Ilmoitus" });
    },
  },
  mounted() {
    this.reloadAll();
  },
};
</script>

<style lang="scss" scoped>
.align-self-start {
  align-selft: flext-start;
}
</style>
