<template>
  <basic-page
    title="Kaluston muokkaus"
    :prev="true"
    :fallback="{ name: 'Kalusto', query: { tab: 'kalusto' } }"
  >
    <v-row class="fill-height">
      <v-col cols="12" lg="12" xl="10" offset-xl="1">
        <kalusto-edit ref="kalustoEdit" />
      </v-col>
    </v-row>
  </basic-page>
</template>
<script>
import BasicPage from "@/components/BasicPage.vue";
import KalustoEdit from "@/views/KalustoEdit.vue";

export default {
  name: "kalusto-edit-view",
  components: { KalustoEdit, BasicPage },
  computed: {
    kalustoEdit() {
      return this.$refs.kalustoEdit || {};
    },
    id() {
      return this.$route.params.id?.toUpperCase();
    },
    isFromQr() {
      return this.$route.query.fromQr === "true";
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.kalustoEdit.viewEquipment(this.id, this.isFromQr);
      } else {
        this.kalustoEdit.addEquipment();
      }
    },
  },
  mounted() {
    if (this.id) {
      this.kalustoEdit.viewEquipment(this.id);
    } else {
      this.kalustoEdit.addEquipment();
    }
  },
};
</script>
