<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card v-if="innerValue">
      <v-card-title>
        <v-spacer />
        <v-btn icon @click="openImageInNewTab">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
        <v-btn icon @click="innerValue = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-img
          :src="innerValue"
          :lazy-src="thumbnail ? thumbnail : null"
          contain
          max-height="75vh"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="innerValue = null">Sulje</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "picture-dialog",
  props: {
    value: {
      type: String,
      default: null,
    },
    thumbnail: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  computed: {
    dialog() {
      return this.innerValue !== null;
    },
  },
  methods: {
    openImageInNewTab() {
      window.open(this.innerValue, "_blank");
    },
  },
  emits: ["input"],
  watch: {
    value() {
      this.innerValue = this.value;
    },
    innerValue() {
      this.$emit("input", this.innerValue);
    },
  },
};
</script>
