<template>
  <basic-page title="Harjoitukset">
    <v-row class="fill-height">
      <v-col cols="12" lg="8" xl="8" offset-xl="2" offset-lg="2">
        <v-row class="mb-5">
          <v-col cols="12" md="6" v-if="nextHarjoitus">
            <v-card>
              <v-list-item>
                <v-list-item-content>
                  <div class="text-overline mb-4">Harjoitukset</div>
                  <v-list-item-subtitle
                    class="text-h6 mb-1 black--text text-wrap"
                    >{{ nextHarjoitus.subject }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-wrap"
                    ><span
                      >{{
                        $formatWeekDayDateAndHourMinute(nextHarjoitus.date)
                      }}
                      {{ formatType(nextHarjoitus.type) }},
                      {{ nextHarjoitus.location }},
                      {{ nextHarjoitus.info }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar>
                  <v-icon>mdi mdi-fireplace</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" v-if="nextEvyHarjoitus">
            <v-card>
              <v-list-item>
                <v-list-item-content>
                  <div class="text-overline mb-4">Evy</div>
                  <v-list-item-subtitle
                    class="text-h6 mb-1 black--text text-wrap"
                    >{{ nextEvyHarjoitus.subject }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-wrap"
                    ><span
                      >{{
                        $formatWeekDayDateAndHourMinute(nextEvyHarjoitus.date)
                      }}
                      {{ formatType(nextEvyHarjoitus.type) }},
                      {{ nextEvyHarjoitus.location }},
                      {{ nextEvyHarjoitus.info }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar>
                  <v-icon>mdi mdi-hospital-box</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <v-progress-linear indeterminate v-if="loading" />
        <v-card class="mt-5">
          <v-card-title>Harjoitukset</v-card-title>
          <v-divider />
          <v-card-text>
            <harjoitukset-table
              :harjoitukset="harjoitukset"
              :next-harjoitus="nextHarjoitus"
            />
          </v-card-text>
        </v-card>
        <v-card class="mt-5">
          <v-card-title> Evy harjoitukset</v-card-title>
          <v-divider />
          <v-card-text>
            <harjoitukset-table
              :harjoitukset="evyharjoitukset"
              :next-harjoitus="nextEvyHarjoitus"
              type="evy"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import dayjs from "dayjs";
import HarjoituksetTable from "@/components/HarjoituksetTable";

export default {
  name: "Harjoitukset",
  components: { HarjoituksetTable, BasicPage },
  data: () => {
    return {
      loading: false,
    };
  },
  computed: {
    today() {
      return dayjs().format("LLLL");
    },
    harjoitukset() {
      return this.$store.state.harjoitukset;
    },
    evyharjoitukset() {
      return this.$store.state.evyharjoitukset;
    },
    nextHarjoitus() {
      const today = dayjs();
      const nextHarjoitus = this.harjoitukset.find((h) =>
        dayjs(h.date).isAfter(today)
      );
      return nextHarjoitus;
    },
    nextEvyHarjoitus() {
      const today = dayjs();
      const nextEvyHarjoitus = this.evyharjoitukset.find((h) =>
        dayjs(h.date).isAfter(today)
      );
      return nextEvyHarjoitus;
    },
  },
  methods: {
    async reloadHarjoitukset() {
      this.loading = true;
      try {
        await this.$store.dispatch("loadHarjoitukset");
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    reloadAll() {
      this.reloadHarjoitukset();
    },
    formatType(type) {
      return type === "OT" ? "Oppitunti" : type === "HT" ? "Harjoitus" : "";
    },
  },
  mounted() {
    this.reloadAll();
  },
};
</script>

<style scoped lang="scss"></style>
