<template>
  <basic-page
    v-if="$isHallitus"
    :fallback="{ name: 'Hallitus' }"
    force-fallback
    prev
    :title="`Hallitus: ${
      types.find((t) => t.value === ilmoitus.type)?.label || 'Ilmoitus'
    } - ${ilmoitus.orderNumber || ''} ${ilmoitus.title || ''}`"
  >
    <v-row class="fill-height">
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab>
            {{
              types.find((t) => t.value === ilmoitus.type)?.label || "Ilmoitus"
            }}
          </v-tab>
          <v-tab v-if="ilmoitus.type === 'kokous'"> Tehtävät </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-col cols="12">
              <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
              <v-form ref="form">
                <v-card v-if="!loading" flat>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="ilmoitus.type"
                          :items="types"
                          item-text="label"
                          item-value="value"
                          label="Tyyppi"
                          outlined
                          hide-details
                          dense
                          :rules="[$rules.required]"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="3" v-if="ilmoitus.type === 'kokous'">
                        <v-text-field
                          v-model="ilmoitus.orderNumber"
                          :rules="[$rules.required]"
                          label="Kokouksen järjestysnumero"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        :md="ilmoitus.type === 'kokous' ? 9 : 12"
                      >
                        <v-text-field
                          v-model="ilmoitus.title"
                          :rules="[$rules.required]"
                          label="Otsikko"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-textarea
                          v-model="ilmoitus.content"
                          auto-grow
                          label="Kuvaus"
                          dense
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" lg="4">
                        <modal-picker
                          v-model="ilmoitus.dateStart"
                          label="Aloituspäivämäärä"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" lg="3">
                        <modal-picker
                          v-model="ilmoitus.timeStart"
                          label="Aloitusaika"
                          type="time"
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <modal-picker
                          v-model="ilmoitus.dateEnd"
                          :error="diff < 0"
                          label="Lopetuspäivämäärä"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" lg="3">
                        <modal-picker
                          v-model="ilmoitus.timeEnd"
                          :error="diff < 0"
                          label="Lopetusaika"
                          type="time"
                          dense
                        />
                      </v-col>
                      <v-col v-if="diff < 0" cols="12">
                        <v-alert type="error"
                          >Lopetusajan pitää olla aloitusajan jälkeen
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="7">
                        <v-text-field
                          v-model="ilmoitus.location"
                          label="Sijainti"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="7">
                        <v-select
                          v-model="ilmoitus.departments"
                          :items="departments"
                          multiple
                          item-text="label"
                          item-value="value"
                          label="Osastot"
                          outlined
                          disabled
                          dense
                          hide-details
                          :rules="[$rules.required]"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="7">
                        <v-switch
                          v-model="ilmoitus.published"
                          label="Julkaistaan yleisen ilmoitustaulun kalenteriin"
                          dense
                          prepend-icon="mdi mdi-calendar"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col v-if="ilmoitus.id" cols="12">
                        <h3>Tiedostot</h3>
                      </v-col>
                      <v-col v-if="ilmoitus.id" cols="12">
                        <file-upload ref="upload"></file-upload>
                        <v-progress-linear v-if="loadingFiles" indeterminate />
                        <template v-for="(file, i) in ilmoitus.files">
                          <v-row
                            :key="i"
                            class="text-wrap info--text d-flex align-center mb-3 cursor-pointer"
                            @click="openFile(file)"
                          >
                            <v-col cols="1">
                              <v-icon>mdi mdi-paperclip</v-icon>
                            </v-col>
                            <v-col cols="10">
                              {{ file.filename }}
                              <div class="caption grey--text">
                                {{ file.createdBy?.user }}
                              </div>
                              <div class="caption grey--text">
                                {{ $formatDateAndHourMinute(file.createdAt) }}
                              </div>
                            </v-col>
                            <v-col cols="1">
                              <v-btn
                                :loading="file._loadingDeleteFile"
                                icon
                                @click.stop="deleteFile(file)"
                              >
                                <v-icon color="error"> mdi mdi-delete</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </template>
                      </v-col>
                    </v-row>
                    <v-divider
                      v-if="
                        ilmoitus.type === 'ilmoittautuminen' ||
                        ilmoitus.type === 'kokous'
                      "
                    />
                    <v-row
                      v-if="
                        ilmoitus.type === 'ilmoittautuminen' ||
                        ilmoitus.type === 'kokous'
                      "
                    >
                      <v-col class="mt-6" cols="12">
                        <h3>Ilmoittautumisen asetukset</h3>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="ilmoitus.participantCount"
                          hint="Jätä tyhjäksi mikäli et halua rajoittaa osallistujamäärää."
                          label="Max osallistujamäärä"
                          outlined
                          persistent-hint
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <ilmoittautuminen-hallitus
                      v-if="$id(ilmoitus)"
                      :ilmoitus="ilmoitus"
                      admin
                      @reload="reloadIlmoitus"
                    />
                  </v-card-text>
                  <v-divider />
                  <v-card-text
                    v-if="ilmoitus && ilmoitus.id && ilmoitus.type === 'kokous'"
                  >
                    <v-row>
                      <v-col class="mt-6" cols="12">
                        <h3>Muodostettavat dokumentit</h3>
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          @click="createKokouskutsu"
                          :loading="loadingKokouskutsu"
                          small
                        >
                          Luo kokouskutsu
                        </v-btn>
                        <span class="ml-2"
                          >Tämä muodostaa kokouskutsun Word-tiedostona sisältäen
                          kaikki käsiteltävät asiat tehtävä-välilehdeltä.</span
                        >
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          @click="createPoytakirja"
                          :loading="loadingPoytakirja"
                          small
                        >
                          Luo pöytäkirja
                        </v-btn>
                        <span class="ml-2"
                          >Tämä muodostaa pöytäkirjan Word-tiedostona sisältäen
                          kaikki käsiteltävät asiat tehtävä-välilehdeltä luoden
                          niihin myös Päätös-otsikon.</span
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-form>
            </v-col>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-divider />
              <v-card-text>
                <v-col
                  cols="12"
                  v-if="ilmoitus.type === 'kokous' && loadingTehtavat"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-col>
              </v-card-text>
              <v-card-text
                v-if="
                  ilmoitus.type === 'kokous' &&
                  tehtavat.length > 0 &&
                  !loadingTehtavat
                "
              >
                <v-row>
                  <v-col cols="12">
                    <!-- Mallitehtavat -->
                    <v-row no-gutters>
                      <h3>Kokouksen tehtävät</h3>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        class="mb-2"
                        small
                        @click="newTehtava"
                        v-if="ilmoitus && ilmoitus.id"
                        ><v-icon class="mr-2">mdi mdi-plus</v-icon> Lisää
                        tehtävä</v-btn
                      >
                    </v-row>
                    <h4 class="mt-3">Käsiteltävät asiat</h4>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th style="width: 50px"></th>
                            <th class="text-left">Järjestysnumero §</th>
                            <th class="text-left">Otsikko</th>
                            <th class="text-left">Vastuuhenkilöt</th>
                            <th class="text-left">Määräpäivä</th>
                            <th class="text-left">Tila</th>
                            <th style="width: 24px"></th>
                            <!--<th class="text-left">Kuvaus</th>-->
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in tehtavat" :key="item.id">
                            <td>
                              <div class="d-flex flex-column">
                                <v-btn
                                  icon
                                  x-small
                                  v-if="index > 0 && item.orderNumber"
                                  @click="moveTehtava(item, index, -1)"
                                  ><v-icon>mdi mdi-arrow-up-thin</v-icon></v-btn
                                >

                                <v-btn
                                  icon
                                  x-small
                                  class="ml-2"
                                  v-if="
                                    index < tehtavat.length - 1 &&
                                    item.orderNumber
                                  "
                                  @click="moveTehtava(item, index, 1)"
                                  ><v-icon
                                    >mdi mdi-arrow-down-thin</v-icon
                                  ></v-btn
                                >
                              </div>
                            </td>
                            <td>
                              {{
                                item.orderNumber ? `${item.orderNumber} §` : ""
                              }}
                            </td>
                            <td>{{ item.title }}</td>
                            <td>
                              {{
                                item.assignedTo &&
                                item.assignedTo
                                  .map((u) => u.user)
                                  .filter(Boolean)
                                  .join(", ")
                              }}
                            </td>
                            <td>{{ $formatDate(item.deadline) }}</td>
                            <td>
                              <tehtava-status-bagde :status="item.status" />
                            </td>
                            <td>
                              <v-btn icon @click="selectedTehtava = item">
                                <v-icon>mdi mdi-arrow-right</v-icon>
                              </v-btn>
                            </td>
                            <!--<td>{{ item.description }}</td>-->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <v-row :class="[$vuetify.breakpoint.xsOnly && 'mt-5']">
                      <v-col cols="6" md="1">
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          label="Aloitusnumero"
                          v-model.number="tehtavaStartOrderNumber"
                          v-if="dirtyTehtavatOrderNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="3">
                        <v-btn
                          icon
                          class="ml-4"
                          color="primary"
                          x-small
                          title="Tallenna tehtävien järjestykset"
                          v-if="dirtyTehtavatOrderNumber"
                          :loading="loadingSaveTehtavaOrderNumbers"
                          @click="saveTehtavaOrderNumbers"
                          ><v-icon>mdi mdi-content-save-outline</v-icon></v-btn
                        >
                        <v-btn
                          icon
                          class="ml-4"
                          color="primary"
                          x-small
                          title="Korjaa järjestysnumerovälit"
                          v-if="!dirtyTehtavatOrderNumber && gapsInTehtavat"
                          :loading="loadingSaveTehtavaOrderNumbers"
                          @click="saveTehtavaOrderNumbers"
                          ><v-icon>mdi mdi-reload</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="mt-12">
                  <v-col cols="12">
                    <h4>Muut tehtävät</h4>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Otsikko</th>
                            <th class="text-left">Vastuuhenkilöt</th>
                            <th class="text-left">Määräpäivä</th>
                            <th class="text-left">Tila</th>
                            <th style="width: 24px"></th>
                            <!--<th class="text-left">Kuvaus</th>-->
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in tehtavatWithoutOrderNumber"
                            :key="item.id"
                          >
                            <td>{{ item.title }}</td>
                            <td>
                              {{
                                item.assignedTo &&
                                item.assignedTo
                                  .map((u) => u.user)
                                  .filter(Boolean)
                                  .join(", ")
                              }}
                            </td>
                            <td>{{ $formatDate(item.deadline) }}</td>
                            <td>
                              <tehtava-status-bagde :status="item.status" />
                            </td>
                            <td>
                              <v-btn icon @click="selectedTehtava = item">
                                <v-icon>mdi mdi-arrow-right</v-icon>
                              </v-btn>
                            </td>
                            <!--<td>{{ item.description }}</td>-->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-text v-if="ilmoitus.type === 'kokous'">
                <h3>Kokoukseen liitettävät mallitehtävät</h3>
                Voit liittää kokoukseen alla olevia tehtäviä.
                <v-row class="mt-2">
                  <v-col cols="12">
                    <v-text-field
                      type="number"
                      v-model="startOrderNumber"
                      label="Aloitusnumero"
                      style="width: 100px"
                      outlined
                      dense
                    />
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Valitse</th>
                            <th class="text-left">Järj.nro</th>
                            <th class="text-left">Otsikko</th>
                            <th class="text-left">Vastuuhenkilöt</th>
                            <!--<th class="text-left">Kuvaus</th>-->
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="filteredMalliTehtavat.length <= 0">
                            <td colspan="100%">Ei mallitehtäviä</td>
                          </tr>
                          <tr
                            v-for="item in filteredMalliTehtavat"
                            :key="item.id"
                          >
                            <td>
                              <!-- checkbox for selecting -->
                              <v-checkbox
                                v-model="item._selected"
                                hide-details
                                dense
                              ></v-checkbox>
                            </td>
                            <td style="width: 100px">
                              <v-text-field
                                dense
                                hide-details
                                outlined
                                type="number"
                                v-model="item._tempOrderNumber"
                              />
                            </td>
                            <td>{{ item.title }}</td>
                            <td>
                              <v-autocomplete
                                v-model="item.assignedTo"
                                outlined
                                dense
                                hide-details
                                multiple
                                :items="users"
                              />
                            </td>
                            <!--<td>{{ item.description }}</td>-->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        <v-divider />
        <v-card flat class="mb-12">
          <v-card-actions>
            <v-btn
              v-if="$id(ilmoitus)"
              :loading="loadingDelete"
              color="error"
              @click="deleteIlmoitus"
              >Poista
            </v-btn>
            <v-spacer />

            <v-btn :loading="loadingSave" color="primary" @click="save"
              >Tallenna
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-divider />
        <tehtavat-hallitus-tehtava-dialog
          v-model="selectedTehtava"
          @reload="reloadTehtavat"
        />
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import IlmoittautuminenHallitus from "@/components/Hallitus/IlmoittautuminenHallitus.vue";
import FileUpload from "@/components/FileUpload";
import api from "@/api";
import ModalPicker from "@/components/ModalPicker.vue";
import dayjs from "dayjs";
import { getBlob, ref } from "firebase/storage";
import downloadjs from "downloadjs";
import { tiedostopankkiHallitusStorage } from "@/plugins/firebase.app";
import { orderBy } from "lodash";
import TehtavatHallitusTehtavaDialog from "@/views/TehtavatHallitusTehtavaDialog.vue";
import TehtavaStatusBagde from "@/views/TehtavaStatusBagde.vue";
import logoAsBase64 from "@/logo_base64";

import {
  Document as DocXDoc,
  ImageRun,
  Packer,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun,
} from "docx";
import { saveAs } from "file-saver";

export default {
  name: "Ilmoitus",
  components: {
    TehtavaStatusBagde,
    TehtavatHallitusTehtavaDialog,
    ModalPicker,
    FileUpload,
    IlmoittautuminenHallitus,
    BasicPage,
  },
  data() {
    return {
      ilmoitus: {
        title: null,
        content: null,
        orderNumber: null,
        type: "ilmoitus",
        participantCount: null,
        departments: [],
        dateStart: null,
        timeStart: null,
        dateEnd: null,
        timeEnd: null,
      },
      loadingMalliTehtavat: false,
      loadingTehtavat: false,
      loadingKokouskutsu: false,
      loadingPoytakirja: false,
      loadingSaveTehtavaOrderNumbers: false,
      loadingFiles: false,
      malliTehtavat: [],
      tehtavat: [],
      tehtavatWithoutOrderNumber: [],
      tab: 0,
      startOrderNumber: 1,
      dirtyTehtavatOrderNumber: false,
      tehtavaStartOrderNumber: null,
      selectedTehtava: null,
      types: [
        {
          value: "ilmoitus",
          label: "Ilmoitus",
        },
        {
          value: "ilmoittautuminen",
          label: "Ilmoittautuminen",
        },
        {
          value: "kokous",
          label: "Kokous",
        },
      ],
      files: [],
      loading: false,
      loadingSave: false,
      loadingDelete: false,

      defaultJakelu: [
        "Joni Leino",
        "Janne Leinonen",
        "Jarno Joensuu",
        "Kai Heikkilä",
        "Piia Kanersalo",
        "Niko Vehkalahti",
        "Laura Laitila",
        "Jarkko Helin",
        "Jari Halme",
        "Joni Rautiainen",
      ],

      defaultOsastonjohtajat: [
        "Jani-Petteri Heiskala",
        "Tuula Toivanen",
        "Aimo Latva",
      ],
      users: [],
    };
  },
  computed: {
    diff() {
      if (
        this.ilmoitus.dateStart &&
        this.ilmoitus.timeStart &&
        this.ilmoitus.dateEnd &&
        this.ilmoitus.timeEnd
      ) {
        const startTime = dayjs(
          this.ilmoitus.date + " " + this.ilmoitus.time,
          "YYYY-MM-DD HH:mm"
        );
        const returnTime = dayjs(
          this.ilmoitus.dateReturn + " " + this.ilmoitus.timeReturn,
          "YYYY-MM-DD HH:mm"
        );
        return returnTime.diff(startTime);
      }
      return null;
    },
    departments() {
      return [
        {
          value: "hallitus",
          label: "Hallitus",
        },
      ];
    },
    id() {
      return this.$id(this.ilmoitus);
    },
    filteredMalliTehtavat: {
      get() {
        const foundTehtavat = this.tehtavat
          .map((t) => t.tehtavaMalliId)
          .filter(Boolean);
        return this.malliTehtavat.filter((t) => !foundTehtavat.includes(t.id));
      },
      set(value) {},
    },
    gapsInTehtavat() {
      return this.tehtavat.some((t, i) => {
        try {
          return parseInt(t.orderNumber) !== this.tehtavaStartOrderNumber + i;
        } catch (e) {
          console.error(e);
          return true;
        }
      });
    },
  },
  methods: {
    async saveTehtavaOrderNumbers() {
      this.loadingSaveTehtavaOrderNumbers = true;
      try {
        await api.tehtavaHallitusOrderNumber(
          this.tehtavat.map((r, i) => {
            return {
              id: r.id,
              orderNumber: this.tehtavaStartOrderNumber + i,
            };
          })
        );
      } catch (e) {
        console.error(e);
        this.$toast("Tehtävien järjestysten tallennus epäonnistui", {
          type: "error",
        });
      }
      this.loadingSaveTehtavaOrderNumbers = false;
      this.dirtyTehtavatOrderNumber = false;
      this.reloadTehtavat();
    },
    moveTehtava(tehtava, index, direction) {
      const tehtavat = [...this.tehtavat];
      const newIndex = index + direction;
      tehtavat.splice(index, 1);
      tehtavat.splice(newIndex, 0, tehtava);
      this.tehtavat = tehtavat.map((t, i) => {
        if (t.orderNumber !== null) {
          t.orderNumber = this.tehtavaStartOrderNumber + i;
        }
        return t;
      });
      this.dirtyTehtavatOrderNumber = true;
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (!this.ilmoitus.type) {
        this.$toast("Valitse ilmoituksen tyyppi", {
          type: "error",
        });
        return;
      }
      if (this.ilmoitus.type === "kokous" && !this.ilmoitus.orderNumber) {
        this.$toast("Anna kokouksen järjestysnumero", {
          type: "error",
        });
        return;
      }
      this.loadingSave = true;
      const files = await this.$refs.upload?.getFiles();

      try {
        // eslint-disable-next-line no-undef
        const _ilmoitus = structuredClone(this.ilmoitus);
        try {
          // eslint-disable-next-line no-undef
          _ilmoitus.malliTehtavat = structuredClone(
            this.malliTehtavat.filter((t) => t._selected)
          ).map((tehtava) => {
            tehtava.assignedTo =
              tehtava.assignedTo
                ?.map((u) => {
                  const user = this.users.find((user) => user.email === u);
                  if (!user) return null;
                  return {
                    email: user.email,
                    sub: user.uid,
                    user: user.displayName,
                  };
                })
                .filter(Boolean) || [];
            return tehtava;
          });
        } catch (e) {
          console.error(e);
        }
        const ilmoitus = (await api.ilmoitusHallitusUpsert(_ilmoitus)).data;

        if (files) {
          for (let filesKey in files) {
            (
              await api.ilmoitusHallitusAddFile({
                ilmoitusId: ilmoitus.id,
                ...files[filesKey],
              })
            ).data;
          }
        }

        if (this.dirtyTehtavatOrderNumber && this.tehtavat?.length > 0) {
          await this.saveTehtavaOrderNumbers();
        }
        this.$toast("Ilmoitus tallennettu.", {
          type: "success",
        });
        this.malliTehtavat.forEach((t) => (t._selected = false));
        if (!this.id) {
          this.$router.push({
            name: "HallitusIlmoitusById",
            params: { id: this.$id(ilmoitus) },
          });
        }
        this.ilmoitus = ilmoitus;
        await this.reloadIlmoitus();
        await Promise.all([this.reloadMalliTehtavat(), this.reloadTehtavat()]);
      } catch (e) {
        this.$toast("Tallennus epäonnistui. Ole hyvä ja yritä uudelleen.", {
          type: "error",
        });
        console.error(e);
      }
      this.loadingSave = false;
    },
    async reloadUsers() {
      this.loadingUsers = true;
      let response = (await api.usersList()).data;
      const users = orderBy(
        response.users,
        ["displayName", "email"],
        ["asc", "asc"]
      );
      users.forEach((u) => {
        u.text = u.displayName;
        u.value = u.email;
      });
      this.users = users;
      this.loadingUsers = false;
    },
    async openFile(file) {
      try {
        const theBlob = await getBlob(
          ref(tiedostopankkiHallitusStorage, file.key)
        );
        downloadjs(theBlob, file.filename, file.fileType);
      } catch (error) {
        console.error(error);
        this.$toast("Tiedostoa ei voitu avata. Yritä uudelleen.", {
          type: "error",
        });
      }
    },
    async deleteFile(file) {
      if (
        !(await this.$deleteConfirm("Haluatko varmasti poistaa tiedoston?"))
      ) {
        return;
      }
      this.loadingDeleteFile = true;
      try {
        await api.ilmoitusHallitusDeleteFile(file);
        await this.reloadIlmoitus();
        this.$toast("Tiedosto poistettu", {
          type: "success",
        });
      } catch (e) {
        console.error(e);
        this.$toast("Tiedoston poistaminen epäonnistui", {
          type: "error",
        });
      }
      this.loadingDelete = false;
    },
    async getIlmoitusFiles() {
      this.loadingFiles = true;
      try {
        const files = (
          await api.ilmoitusHallitusFiles({
            ilmoitusId: this.ilmoitus.id,
          })
        )?.data;

        this.ilmoitus.files = files.map((f) => {
          f._loadingDeleteFile = false;
          return f;
        });
      } catch (e) {
        console.error(e);
        this.$toast("Tiedostojen haku ei onnistunut", {
          type: "error",
        });
      }
      this.loadingFiles = false;
    },
    async reloadIlmoitus() {
      this.loading = true;
      const ilmoitus = (
        await api.ilmoitusHallitusGet({ id: this.$route.params.id })
      ).data;
      if (
        ilmoitus.participants?.findIndex((il) => il.sub === this.$user.uid) > -1
      ) {
        ilmoitus._participant = true;
      }
      ilmoitus.files?.forEach((f) => {
        f._loadingDeleteFile = false;
      });
      this.ilmoitus = ilmoitus;
      this.loading = false;
    },
    async deleteIlmoitus() {
      if (
        !(await this.$deleteConfirm("Haluatko varmasti poistaa ilmoituksen?"))
      ) {
        return;
      }
      this.loadingDelete = true;
      try {
        await api.ilmoitusHallitusDelete(this.ilmoitus);
        this.$toast("Ilmoitus poistettu", {
          type: "success",
        });
        this.$router.push({ name: "Hallitus" });
      } catch (e) {
        console.error(e);
        this.$toast("Poistaminen epäonnistui", {
          type: "error",
        });
      }
      this.loadingDelete = false;
    },
    async reloadMalliTehtavat() {
      this.loadingMalliTehtavat = true;
      try {
        this.malliTehtavat = orderBy(
          (await api.tehtavaHallitusList({ type: "malli" }))?.data?.map(
            (tehtava) => {
              tehtava._tempOrderNumber = tehtava.orderNumber;
              tehtava.assignedTo =
                tehtava.assignedTo?.map((u) => u.email) || [];
              tehtava._selected = false;
              return tehtava;
            }
          ),
          ["orderNumber"],
          ["asc"]
        );
      } catch (e) {
        console.error(e);
        this.$toast("Mallitehtävien haku ei onnistunut", { type: "error" });
      }
      this.loadingMalliTehtavat = false;
    },
    newTehtava() {
      this.selectedTehtava = {
        ilmoitusId: this.ilmoitus?.id,
        deadline: this.ilmoitus?.dateStart
          ? dayjs(this.ilmoitus?.dateStart, "YYYY-MM-DD")
              .add(-7, "days")
              .startOf("day")
              .format("YYYY-MM-DD")
          : undefined,
      };
    },
    async reloadTehtavat() {
      if (!this.ilmoitus?.id) return true;
      this.loadingTehtavat = true;
      try {
        const tehtavat = (
          await api.tehtavaHallitusList({
            type: "tehtava",
            ilmoitusId: this.ilmoitus?.id,
          })
        )?.data?.map((tehtava) => {
          if (tehtava.orderNumber) {
            tehtava.orderNumber = parseInt(tehtava.orderNumber);
          } else {
            tehtava.orderNumber = null;
          }
          return tehtava;
        });
        this.tehtavat = orderBy(
          tehtavat.filter((t) => t.orderNumber) || [],
          ["orderNumber"],
          ["asc"]
        );
        this.tehtavatWithoutOrderNumber = orderBy(
          tehtavat.filter((t) => !t.orderNumber) || [],
          ["createdAt"],
          ["asc"]
        );
        this.tehtavaStartOrderNumber = Math.min(
          ...this.tehtavat.map((t) => {
            try {
              return parseInt(t.orderNumber);
            } catch (e) {
              console.error(e);
              return 9999999;
            }
          })
        );
      } catch (e) {
        console.error(e);
        this.$toast("Tehtävien haku ei onnistunut", { type: "error" });
      }
      this.loadingTehtavat = false;
    },
    async createKokouskutsu() {
      this.loadingKokouskutsu = true;
      try {
        const orderNumbers = this.tehtavat
          .filter((t) => t.type === "tehtava")
          .map((t) => (t.orderNumber ? parseInt(t.orderNumber) : null))
          .filter(Boolean);
        const minOrderNumber = Math.min(...orderNumbers);
        const maxOrderNumber = Math.max(...orderNumbers);

        const tabStops = [
          // no need to define first left tab column
          // the right aligned tab column position should point to the end of column
          // i.e. in this case
          // (end position of 1st) + (end position of current)
          // columnWidth + columnWidth = columnWidth * 2

          { type: TabStopType.LEFT, position: 1200 },
          { type: TabStopType.LEFT, position: 2500 },
          { type: TabStopType.RIGHT, position: TabStopPosition.MAX },
        ];

        const doc = new DocXDoc({
          styles: {
            default: {
              document: {
                run: {
                  font: "Times New Roman", // Set default font for text
                  size: 22, // Font size (half-points, 24 = 12pt)
                  color: "000000", // Font color in hex (black)
                },
                paragraph: {
                  spacing: {
                    line: 276, // Line spacing in twips (276 = 1.5 lines)
                  },
                },
              },
            },
          },

          sections: [
            {
              properties: {},
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "KOKOUSKUTSU",
                      bold: true,
                    }),
                    new TextRun({
                      text:
                        [this.ilmoitus.title, this.ilmoitus.orderNumber]
                          .map((t) => t?.toUpperCase())
                          .join(" ") + ".",
                      bold: true,
                      break: 1,
                    }),
                  ],
                  alignment: "end",
                }),
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: Buffer.from(logoAsBase64.logo, "base64"),
                      transformation: {
                        width: 706 / 5,
                        height: 827 / 5,
                      },
                      floating: {
                        horizontalPosition: {
                          offset: 1014400,
                        },
                        verticalPosition: {
                          offset: 601440,
                        },
                      },
                      type: "png",
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "",
                      break: 3,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Noormarkun vapaaehtoinen palokunta ry:n",
                      bold: true,
                    }),
                    new TextRun({
                      text:
                        "hallituksen kokous § " +
                        minOrderNumber +
                        " - " +
                        maxOrderNumber,
                      break: 1,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "",
                      break: 1,
                    }),
                  ],
                }),
                new Paragraph({
                  keepLines: true,
                  children: [
                    new TextRun({
                      text:
                        "Aika: " + this.$formatDate(this.ilmoitus.dateStart),
                      bold: true,
                    }),
                    new TextRun({
                      text: " klo " + this.ilmoitus.timeStart,
                      bold: true,
                    }),
                    new TextRun({
                      text: `Paikka: ${this.ilmoitus.location}`,
                      break: 1,
                    }),
                  ],
                }),
                new Paragraph({
                  keepLines: true,
                  tabStops: tabStops,
                  children: [
                    new TextRun({
                      text: "JAKELU",
                      bold: true,
                      break: 2,
                    }),
                    ...this.defaultJakelu.map((n) => {
                      return new TextRun({
                        text: `\t${n}`,
                        bold: true,
                        break: 1,
                      });
                    }),
                    new TextRun({
                      text: "\tOSASTONJOHTAJAT",
                      bold: true,
                      break: 2,
                    }),
                    ...this.defaultOsastonjohtajat.map((n) => {
                      return new TextRun({
                        text: `\t${n}`,
                        bold: true,
                        break: 1,
                      });
                    }),
                  ],
                }),

                new Paragraph({
                  children: [
                    new TextRun({
                      text: "",
                      break: 2,
                    }),
                  ],
                }),
                ...this.tehtavat.flatMap((t) => {
                  const m = [
                    new TextRun({
                      text: t.orderNumber ? `${t.orderNumber} §` : "",
                      bold: true,
                      break: 2,
                    }),
                    new TextRun({
                      text: `\t${t.title}`,
                      bold: true,
                    }),
                  ];
                  const e = [];
                  if (
                    t.descriptionKokouskutsu &&
                    t.descriptionKokouskutsu?.trim()?.length > 0
                  ) {
                    m.push(
                      new TextRun({
                        text: `\tEhdotus päätökseksi:`,
                        break: 2,
                      })
                    );
                    const descriptionKokouskutsuLines =
                      t.descriptionKokouskutsu?.split("\n") || [];
                    e.push(
                      new Paragraph({
                        keepLines: true,
                        children: [
                          ...descriptionKokouskutsuLines?.map(
                            (row, i) =>
                              new TextRun({
                                text: `${row}`,
                                break: i > 0 ? 1 : 0,
                              })
                          ),
                        ],
                        indent: {
                          start: 3500,
                        },
                      })
                    );
                  }
                  const p = new Paragraph({
                    tabStops: tabStops,
                    children: m,
                    keepLines: true,
                  });

                  return [p, ...e].filter(Boolean);
                }),
                new Paragraph({
                  keepLines: true,
                  children: [
                    new TextRun({
                      text: "Noormarkussa " + this.$formatDate(dayjs()),
                      break: 5,
                    }),
                    new TextRun({
                      text: "Joni Leino",
                      bold: true,
                      break: 2,
                    }),
                    new TextRun({
                      text: "puheenjohtaja",
                      break: 1,
                    }),
                    new TextRun({
                      text: "Noormarkun VPK ry",
                      break: 1,
                    }),
                  ],
                }),
              ],
            },
          ],
        });
        Packer.toBlob(doc).then((blob) => {
          saveAs(
            blob,
            `Kokouskutsu_${dayjs(this.ilmoitus.dateStart).year()}-${
              this.ilmoitus.orderNumber
            }.docx`
          );
          console.log("Document created successfully");
        });
      } catch (e) {
        console.error(e);
      }
      this.loadingKokouskutsu = false;
    },
    async createPoytakirja() {
      this.loadingPoytakirja = true;
      try {
        const orderNumbers = this.tehtavat
          .filter((t) => t.type === "tehtava")
          .map((t) => (t.orderNumber ? parseInt(t.orderNumber) : null))
          .filter(Boolean);
        const minOrderNumber = Math.min(...orderNumbers);
        const maxOrderNumber = Math.max(...orderNumbers);

        const tabStops = [
          // no need to define first left tab column
          // the right aligned tab column position should point to the end of column
          // i.e. in this case
          // (end position of 1st) + (end position of current)
          // columnWidth + columnWidth = columnWidth * 2

          { type: TabStopType.LEFT, position: 1200 },
          { type: TabStopType.LEFT, position: 2500 },
          { type: TabStopType.RIGHT, position: TabStopPosition.MAX },
        ];

        const doc = new DocXDoc({
          styles: {
            default: {
              document: {
                run: {
                  font: "Times New Roman", // Set default font for text
                  size: 22, // Font size (half-points, 24 = 12pt)
                  color: "000000", // Font color in hex (black)
                },
                paragraph: {
                  spacing: {
                    line: 276, // Line spacing in twips (276 = 1.5 lines)
                  },
                },
              },
            },
          },

          sections: [
            {
              properties: {},
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "PÖYTÄKIRJA",
                      bold: true,
                    }),
                    new TextRun({
                      text:
                        [this.ilmoitus.title, this.ilmoitus.orderNumber]
                          .map((t) => t?.toUpperCase())
                          .join(" ") + ".",
                      bold: true,
                      break: 1,
                    }),
                  ],
                  alignment: "end",
                }),
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: Buffer.from(logoAsBase64.logo, "base64"),
                      transformation: {
                        width: 706 / 5,
                        height: 827 / 5,
                      },
                      floating: {
                        horizontalPosition: {
                          offset: 1014400,
                        },
                        verticalPosition: {
                          offset: 601440,
                        },
                      },
                      type: "png",
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "",
                      break: 3,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Noormarkun vapaaehtoinen palokunta ry:n",
                      bold: true,
                    }),
                    new TextRun({
                      text:
                        "hallituksen kokous § " +
                        minOrderNumber +
                        " - " +
                        maxOrderNumber,
                      break: 1,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "",
                      break: 1,
                    }),
                  ],
                }),
                new Paragraph({
                  keepLines: true,
                  children: [
                    new TextRun({
                      text:
                        "Aika: " + this.$formatDate(this.ilmoitus.dateStart),
                      bold: true,
                    }),
                    new TextRun({
                      text: " klo " + this.ilmoitus.timeStart,
                      bold: true,
                    }),
                    new TextRun({
                      text: `Paikka: ${this.ilmoitus.location}`,
                      break: 1,
                    }),
                  ],
                }),
                new Paragraph({
                  keepLines: true,
                  tabStops: tabStops,
                  children: [
                    new TextRun({
                      text: "JAKELU",
                      bold: true,
                      break: 2,
                    }),
                    ...this.defaultJakelu.map((n) => {
                      return new TextRun({
                        text: `\t${n}`,
                        bold: true,
                        break: 1,
                      });
                    }),
                    new TextRun({
                      text: "\tOSASTONJOHTAJAT",
                      bold: true,
                      break: 2,
                    }),
                    ...this.defaultOsastonjohtajat.map((n) => {
                      return new TextRun({
                        text: `\t${n}`,
                        bold: true,
                        break: 1,
                      });
                    }),
                  ],
                }),

                new Paragraph({
                  children: [
                    new TextRun({
                      text: "",
                      break: 2,
                    }),
                  ],
                }),
                ...this.tehtavat.flatMap((t) => {
                  const m = [
                    new TextRun({
                      text: t.orderNumber ? `${t.orderNumber} §` : "",
                      bold: true,
                      break: 2,
                    }),
                    new TextRun({
                      text: `\t${t.title}`,
                      bold: true,
                    }),
                  ];
                  const e = [];
                  if (
                    t.descriptionKokouskutsu &&
                    t.descriptionKokouskutsu?.trim()?.length > 0
                  ) {
                    m.push(
                      new TextRun({
                        text: `\tEhdotus päätökseksi:`,
                        break: 2,
                      })
                    );
                    const descriptionKokouskutsuLines =
                      t.descriptionKokouskutsu?.split("\n") || [];
                    e.push(
                      new Paragraph({
                        keepLines: true,
                        children: [
                          ...descriptionKokouskutsuLines?.map(
                            (row, i) =>
                              new TextRun({
                                text: `${row}`,
                                break: i > 0 ? 1 : 0,
                              })
                          ),
                        ],
                        indent: {
                          start: 3500,
                        },
                      })
                    );
                  }
                  const ehdotus = new Paragraph({
                    tabStops: tabStops,
                    children: m,
                    keepLines: true,
                  });

                  const paatosRow = [];
                  const paatosRowDescription = [];
                  paatosRow.push(
                    new TextRun({
                      text: `\tPäätös:`,
                      break: 1,
                    })
                  );

                  const descriptionKokouskutsuLines =
                    t.descriptionKokouskutsu?.split("\n") || ["-"];
                  paatosRowDescription.push(
                    new Paragraph({
                      keepLines: true,
                      children: [
                        ...descriptionKokouskutsuLines?.map(
                          (row, i) =>
                            new TextRun({
                              text: `${row}`,
                              break: i > 0 ? 1 : 0,
                            })
                        ),
                      ],
                      indent: {
                        start: 3500,
                      },
                    })
                  );

                  const paatos = new Paragraph({
                    tabStops: tabStops,
                    children: paatosRow,
                    keepLines: true,
                  });

                  return [
                    ehdotus,
                    ...e,
                    paatos,
                    ...paatosRowDescription,
                  ].filter(Boolean);
                }),
                new Paragraph({
                  keepLines: true,
                  children: [
                    new TextRun({
                      text: "Noormarkussa " + this.$formatDate(dayjs()),
                      break: 5,
                    }),
                    new TextRun({
                      text: "Joni Leino",
                      bold: true,
                      break: 2,
                    }),
                    new TextRun({
                      text: "puheenjohtaja",
                      break: 1,
                    }),
                    new TextRun({
                      text: "Noormarkun VPK ry",
                      break: 1,
                    }),
                  ],
                }),
              ],
            },
          ],
        });
        Packer.toBlob(doc).then((blob) => {
          saveAs(
            blob,
            `Pöytäkirja_${dayjs(this.ilmoitus.dateStart).year()}-${
              this.ilmoitus.orderNumber
            }.docx`
          );
          console.log("Document created successfully");
        });
      } catch (e) {
        console.error(e);
      }
      this.loadingPoytakirja = false;
    },
  },

  watch: {
    startOrderNumber(val) {
      this.malliTehtavat.forEach((m) => {
        try {
          m._tempOrderNumber = parseInt(m.orderNumber) + parseInt(val) - 1;
        } catch (e) {
          console.error(e);
        }
      });
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      await this.reloadIlmoitus();
    } else {
      this.ilmoitus.departments = [this.$isHallitus && "hallitus"].filter(
        Boolean
      );
    }
    await Promise.all([
      this.reloadUsers(),
      this.reloadMalliTehtavat(),
      this.reloadTehtavat(),
    ]);
  },
};
</script>

<style lang="scss" scoped></style>
