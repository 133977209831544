<template>
  <v-btn
    :color="color"
    small
    v-bind="$attrs"
    :loading="loadingStatus"
    @click="setStatus"
  >
    <v-icon class="mr-2" v-if="currentValue !== status"
      >mdi mdi-radiobox-blank
    </v-icon>
    <v-icon class="mr-2" v-if="currentValue === status"
      >mdi mdi-radiobox-marked
    </v-icon>
    {{ label }}
  </v-btn>
</template>
<script>
import { doc, updateDoc } from "firebase/firestore";
import { firestore as db } from "@/plugins/firebase.app";

export default {
  name: "huolto-status-button",
  emits: ["input", "change"],
  props: {
    huolto: {},
    color: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    iconOn: {
      type: String,
      default: "mdi mdi-radiobox-marked",
    },
    iconOff: {
      type: String,
      default: "mdi mdi-radiobox-blank",
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingStatus: false,
      currentValue: this.value,
    };
  },
  watch: {
    value() {
      this.currentValue = this.value;
    },
  },
  methods: {
    async setStatus() {
      if (!this.huolto?.id) return;
      this.loadingStatus = true;
      try {
        await updateDoc(doc(db, "huollot", this.huolto.id), {
          status: this.status,
        });
        this.$emit("input", this.status);
        this.$emit("change", { status: this.status, label: this.label });
      } catch (error) {
        console.error(error);
      }
      this.loadingStatus = false;
    },
  },
};
</script>
