<template>
  <v-dialog
    v-model="isOpen"
    persistent
    max-width="800"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="mt-5">
      <v-card-title
        >Tehtävä - {{ statuses.find((s) => s.value === tehtava.status)?.text }}
        <v-spacer />
      </v-card-title>
      <v-divider />

      <v-skeleton-loader
        v-if="loadingTehtava"
        type="article, actions"
      ></v-skeleton-loader>

      <v-card-text v-if="!loadingTehtava" class="pt-5">
        <v-row>
          <v-col cols="12" v-if="tehtava && tehtava.id && type === 'tehtava'">
            <!-- Button for marking status as "STARTED" and if it's already started, mark as "READY" -->

            <!--<div class="text-subtitle-2 mb-2">Tämän hetkinen tila:</div>
            <v-chip
              v-if="tehtava.status"
              :color="
                tehtava.status === 'NOT_STARTED'
                  ? 'red lighten-2'
                  : tehtava.status === 'IN_PROGRESS'
                  ? 'orange'
                  : 'green'
              "
              :class="'white--text mb-2'"
              small
              >{{
                statuses.find((s) => s.value === tehtava.status)?.text
              }}</v-chip
            >-->
            <div class="text-subtitle-2 mb-2">Vaihda tila:</div>
            <v-row no-gutters style="gap: 7px">
              <v-btn
                color="error"
                :loading="loadingStatusNotStarted"
                small
                class=""
                @click="setStatus('NOT_STARTED')"
              >
                <v-icon class="mr-2" v-if="tehtava.status !== 'NOT_STARTED'"
                  >mdi mdi-radiobox-blank</v-icon
                >
                <v-icon class="mr-2" v-if="tehtava.status === 'NOT_STARTED'"
                  >mdi mdi-radiobox-marked</v-icon
                >
                Aloittamatta</v-btn
              >
              <v-btn
                color="warning"
                small
                class=""
                :loading="loadingStatusInProgress"
                @click="setStatus('IN_PROGRESS')"
              >
                <v-icon class="mr-2" v-if="tehtava.status !== 'IN_PROGRESS'"
                  >mdi mdi-radiobox-blank</v-icon
                >
                <v-icon class="mr-2" v-if="tehtava.status === 'IN_PROGRESS'"
                  >mdi mdi-radiobox-marked</v-icon
                >
                Kesken</v-btn
              >
              <v-btn
                color="success"
                small
                class=""
                :loading="loadingStatusReady"
                @click="setStatus('READY')"
              >
                <v-icon class="mr-2" v-if="tehtava.status !== 'READY'"
                  >mdi mdi-radiobox-blank</v-icon
                >
                <v-icon class="mr-2" v-if="tehtava.status === 'READY'"
                  >mdi mdi-radiobox-marked</v-icon
                >
                Valmis</v-btn
              >
            </v-row>
            <v-divider class="mt-5" />
          </v-col>

          <v-col cols="12" v-if="loadingIlmoitus">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
          <v-col cols="12" v-if="tehtava.ilmoitus">
            <v-row align="center">
              <v-col cols="auto">
                <v-icon class="mr-2" style="font-size: 34px"
                  >mdi mdi-gavel</v-icon
                >
              </v-col>
              <v-col cols="auto">
                <div class="text-subtitle-2">Kokous:</div>
                <div class="text-subtitle-1 mt-2">
                  <strong>{{ tehtava.ilmoitus?.title }}</strong>
                </div>

                <div v-if="tehtava.ilmoitus.dateStart">
                  <span class="caption">
                    {{
                      $capitalFirstLetter(
                        $formatWeekDay(
                          tehtava.ilmoitus.dateStart +
                            " " +
                            (tehtava.ilmoitus.timeStart || "00:00:00")
                        )
                      )
                    }}
                    {{
                      $formatDateAndHourMinute(
                        tehtava.ilmoitus.dateStart +
                          " " +
                          (tehtava.ilmoitus.timeStart || "00:00:00")
                      )
                    }}</span
                  >

                  <span v-if="tehtava.ilmoitus.dateEnd" class="caption">
                    -
                    {{
                      $capitalFirstLetter(
                        $formatWeekDay(
                          tehtava.ilmoitus.dateEnd +
                            " " +
                            (tehtava.ilmoitus.timeEnd || "00:00:00")
                        )
                      )
                    }}
                    {{
                      $formatDateAndHourMinute(
                        tehtava.ilmoitus.dateEnd +
                          " " +
                          (tehtava.ilmoitus.timeEnd || "00:00:00")
                      )
                    }}</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              :disabled="!hasEditRights"
              v-model.number="tehtava.orderNumber"
              label="Järjestysnumero"
              outlined
              hide-details
              dense
            >
              <template v-slot:append> § </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              :disabled="!hasEditRights"
              v-model="tehtava.title"
              label="Tehtävän otsikko"
              outlined
              hide-details
              dense
            />
          </v-col>
          <v-col cols="12">
            <v-divider class="mb-5" />
            <v-autocomplete
              v-model="tehtava.assignedTo"
              :disabled="!hasEditRights"
              label="Vastuuhenkilöt"
              outlined
              hide-details
              multiple
              :items="users"
              dense
            />
          </v-col>
          <v-col cols="12" v-if="type !== 'malli'">
            <modal-picker
              :disabled="!hasEditRights"
              type="date"
              v-model="tehtava.deadline"
              label="Määräaika"
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="tehtava.priority"
              label="Prioriteetti"
              outlined
              :disabled="!hasEditRights"
              hide-details
              dense
              :items="[
                { text: 'Korkea', value: 2 },
                { text: 'Keskikorkea', value: 1 },
                { text: 'Matala', value: 0 },
              ]"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="tehtava.status"
              label="Tila"
              outlined
              :disabled="!hasEditRights"
              hide-details
              dense
              :items="statuses"
            />
          </v-col>
          <v-divider />
          <v-col cols="12">
            <v-divider class="mb-5" />

            <v-textarea
              auto-grow
              v-model="tehtava.description"
              :disabled="!hasEditRights"
              outlined
              label="Kuvaus"
              dense
            />

            <v-textarea
              auto-grow
              v-model="tehtava.descriptionKokouskutsu"
              :disabled="!hasEditRights"
              outlined
              label="Kuvaus kokouskutsussa"
              dense
            />

            <!--<editor-content
              :editor="editor"
              class="v-textarea"
              style="width: 100%; min-height: 300px"
            />
            <v-menu
              v-if="showMenu"
              :value="menuVisible"
              :position-x="menuPosition.x"
              :position-y="menuPosition.y"
              absolute
              offset-y
              :close-on-click="false"
              :close-on-content-click="false"
            >
              <v-card>
                <v-card-text class="pa-1">
                  <v-btn
                    v-for="(item, index) in menuItems"
                    :key="index"
                    small
                    text
                    @click="item.action"
                    :class="{ 'v-btn--active': item.active() }"
                  >
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-menu>-->
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider class="mb-4" />
      <v-card-subtitle
        v-if="
          !loadingTehtava &&
          tehtava &&
          tehtava.id &&
          type !== 'malli' &&
          tehtava.ilmoitusId
        "
      >
        <v-row>
          <v-col cols="12">
            <div class="text-h6 mb-3">Tiedostot</div>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text
        v-if="
          !loadingTehtava &&
          tehtava &&
          tehtava.id &&
          type !== 'malli' &&
          tehtava.ilmoitusId
        "
      >
        <v-row>
          <v-col v-if="tehtava.id" cols="12">
            <file-upload
              ref="upload"
              label="<div class='d-flex align-center'><div>
              <i class='mdi mdi-cloud-upload-outline' style='font-size: 32px; margin-right: 20px;'></i>
              </div><div>
              <strong>Paina tästä lisätäksesi tiedostoja. Tiedostot lisätään automaattisesti hallituksen tiedostopankkiin.</strong></div></div>"
              @update:files="
                (files) => (hasUploadableFiles = files?.length > 0)
              "
            >
              <template v-slot:append="{ hasFiles }">
                <v-row no-gutters>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    class="my-2"
                    @click="saveFiles"
                    v-if="hasFiles"
                    :loading="loadingFileSave"
                    :disabled="!hasUploadableFiles"
                    small
                    >Tallenna tiedostot</v-btn
                  >
                </v-row>
              </template>
            </file-upload>
            <v-progress-linear v-if="loadingFiles" indeterminate />
            <template v-for="(file, i) in tehtava.files">
              <v-row
                :key="i"
                class="text-wrap info--text d-flex align-center mb-3 cursor-pointer"
                @click="openFile(file)"
              >
                <v-col cols="1">
                  <v-icon>mdi mdi-paperclip</v-icon>
                </v-col>
                <v-col cols="10">
                  {{ file.filename }}
                  <div class="caption grey--text">
                    {{ file.createdBy?.user }}
                  </div>
                  <div class="caption grey--text">
                    {{ $formatDateAndHourMinute(file.createdAt) }}
                  </div>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    :loading="file._loadingDeleteFile"
                    icon
                    v-if="file.createdBy?.sub === $userDBContext.sub"
                    @click.stop="deleteFile(file)"
                  >
                    <v-icon color="error"> mdi mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="mb-4"></v-divider>
      <v-card-subtitle
        v-if="!loadingTehtava && tehtava && tehtava.id && type !== 'malli'"
      >
        <v-row>
          <v-col cols="12">
            <div class="text-h6">Kommentit</div>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text
        v-if="!loadingTehtava && tehtava && tehtava.id && type !== 'malli'"
      >
        <!-- Comments Section -->
        <v-row>
          <v-col cols="12">
            <!-- Add Comment Input -->
            <v-row class="mt-4">
              <v-col cols="12">
                <v-textarea
                  v-model="newComment.comment"
                  label="Lisää kommentti"
                  outlined
                  auto-grow
                  :disabled="!tehtava.id"
                  append-icon="mdi-send"
                  @click:append="addComment"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <!-- Comments List -->
            <v-list v-if="tehtava.comments && tehtava.comments.length">
              <v-list-item
                v-for="(comment, index) in sortedComments"
                :key="index"
                class="comment-item"
              >
                <v-list-item-avatar>
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="mb-2 text-wrap">
                    {{
                      (comment.createdBy && comment.createdBy.user) ||
                      "Nimetön käyttäjä"
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-wrap text-pre-line">
                    {{ comment.comment }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    class="grey--text text-caption text-wrap"
                  >
                    {{ $formatDateAndHourMinute(comment.createdAt) }}
                    <v-icon v-if="comment.edited" small class="ml-1">
                      mdi-pencil
                    </v-icon>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <!-- Comment Actions (Edit/Delete) -->
                <v-list-item-action v-if="canModifyComment(comment)">
                  <v-btn icon @click="editComment(comment)">
                    <v-icon
                      small
                      :title="`Muokattu ${$formatDateAndHourMinute(
                        comment.editedAt
                      )}`"
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                  <v-btn
                    icon
                    @click="deleteComment(comment)"
                    :loading="comment._deletingComment"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-alert v-else type="info" outlined text class="mt-3">
              Ei kommentteja
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <v-btn
          color="error"
          class="my-2"
          @click="deleteTehtava"
          :loading="loadingDelete"
          v-if="hasEditRights && tehtava.id"
          >Poista</v-btn
        >
        <v-spacer />
        <v-btn color="default" text class="my-2" @click="isOpen = false"
          >Sulje</v-btn
        >
        <v-btn
          color="primary"
          class="my-2"
          @click="saveTehtava"
          :loading="loadingSave"
          v-if="hasEditRights"
          >Tallenna</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- Edit Comment Dialog -->
    <v-dialog
      v-model="editCommentDialog"
      max-width="800"
      persistent
      v-if="editingComment"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>Muokkaa kommenttia</v-card-title>
        <v-card-text>
          <v-textarea
            auto-grow
            v-model="editingComment.comment"
            label="Kommentti"
            outlined
            autofocus
          ></v-textarea>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="editCommentDialog = false">
            Peruuta
          </v-btn>
          <v-btn color="primary" @click="saveEditedComment"> Tallenna </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import ModalPicker from "@/components/ModalPicker.vue";
import api from "@/api";
import { orderBy } from "lodash";
import FileUpload from "@/components/FileUpload.vue";
import { getBlob, ref } from "firebase/storage";
import { tiedostopankkiHallitusStorage } from "@/plugins/firebase.app";
import downloadjs from "downloadjs";

const newTehtava = {
  id: null,
  orderNumber: null,
  title: null,
  description: null,
  descriptionKokouskutsu: null,
  deadline: null,
  assignedTo: [],
  priority: 0,
  createdBy: "",
  createdAt: "",
  completed: false,
  completedBy: null,
  completedAt: null,
  updatedAt: null,
  updatedBy: null,
  comments: [],
  status: "NOT_STARTED",
  ilmoitusId: null,
  files: [],
};
const newComment = {
  comment: "",
  edited: null,
  createdBy: null,
  createdAt: null,
  editedAt: null,
  editedBy: null,
};
export default {
  name: "TehtavatHallitusTehtavaDialog",
  components: {
    FileUpload,
    ModalPicker,
    //EditorContent,
  },
  props: {
    value: Object,
    type: {
      type: String,
      default: "tehtava",
    },
  },
  data() {
    return {
      // eslint-disable-next-line no-undef
      tehtava: structuredClone({ ...newTehtava, type: this.type }),
      comment: null,
      //editor: null,
      //menuVisible: false,
      //menuPosition: { x: 0, y: 0 },
      loadingUsers: false,
      loadingFiles: false,
      loadingDeleteFile: false,
      loadingFileSave: false,
      users: [],
      loadingSave: false,
      loadingTehtava: false,
      loadingDelete: false,
      hasUploadableFiles: false,
      isOpen: !!this.value,
      // eslint-disable-next-line no-undef
      newComment: structuredClone({ ...newComment }),
      editingComment: null,
      editCommentDialog: false,
      loadingIlmoitus: false,
      loadingStatus: false,
      loadingStatusReady: false,
      loadingStatusInProgress: false,
      loadingStatusNotStarted: false,
      statuses: [
        { text: "Aloittamatta", value: "NOT_STARTED" },
        { text: "Kesken", value: "IN_PROGRESS" },
        { text: "Valmis", value: "READY" },
      ],
    };
  },
  emits: ["input", "reload"],
  computed: {
    hasEditRights() {
      let emailList = ["jonirautiainen@gmail.com", "joni.leino88@gmail.com"];
      return (
        !this.tehtava?.id ||
        this.tehtava.createdBy?.sub === this.$user.uid ||
        this.tehtava.type === "malli" ||
        emailList.includes(this.$userInfo?.email)
      );
    },
    sortedComments() {
      // Sort comments by creation date, most recent first
      return orderBy(this.tehtava.comments || [], "createdAt", "desc");
    },
    /*showMenu() {
      return this.editor && this.menuVisible;
    },
    menuItems() {
      return [
        {
          label: "Bold",
          icon: "mdi-format-bold",
          active: () => this.editor.isActive("bold"),
          action: () => this.editor.chain().focus().toggleBold().run(),
        },
        {
          label: "Italic",
          icon: "mdi-format-italic",
          active: () => this.editor.isActive("italic"),
          action: () => this.editor.chain().focus().toggleItalic().run(),
        },
        {
          label: "Strike",
          icon: "mdi-format-strikethrough",
          active: () => this.editor.isActive("strike"),
          action: () => this.editor.chain().focus().toggleStrike().run(),
        },
        {
          label: "Code",
          icon: "mdi-code-tags",
          active: () => this.editor.isActive("code"),
          action: () => this.editor.chain().focus().toggleCode().run(),
        },
        {
          label: "Heading 1",
          icon: "mdi-format-header-1",
          active: () => this.editor.isActive("heading", { level: 1 }),
          action: () =>
            this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
        },
        {
          label: "Heading 2",
          icon: "mdi-format-header-2",
          active: () => this.editor.isActive("heading", { level: 2 }),
          action: () =>
            this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
        },
        {
          label: "Heading 3",
          icon: "mdi-format-header-3",
          active: () => this.editor.isActive("heading", { level: 3 }),
          action: () =>
            this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
        },
        {
          label: "Bullet List",
          icon: "mdi-format-list-bulleted",
          active: () => this.editor.isActive("bulletList"),
          action: () => this.editor.chain().focus().toggleBulletList().run(),
        },
        {
          label: "Ordered List",
          icon: "mdi-format-list-numbered",
          active: () => this.editor.isActive("orderedList"),
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
        },
        {
          label: "Blockquote",
          icon: "mdi-format-quote-close",
          active: () => this.editor.isActive("blockquote"),
          action: () => this.editor.chain().focus().toggleBlockquote().run(),
        },
        {
          label: "Code Block",
          icon: "mdi-code-braces",
          active: () => this.editor.isActive("codeBlock"),
          action: () => this.editor.chain().focus().toggleCodeBlock().run(),
        },
        {
          label: "Horizontal Rule",
          icon: "mdi-minus",
          active: () => false, // Horizontal rule is not a toggle
          action: () => this.editor.chain().focus().setHorizontalRule().run(),
        },
        {
          label: "Hard Break",
          icon: "mdi-arrow-down",
          active: () => false, // Hard break is not a toggle
          action: () => this.editor.chain().focus().setHardBreak().run(),
        },
        {
          label: "Undo",
          icon: "mdi-undo",
          active: () => false, // Undo is not a toggle
          action: () => this.editor.chain().focus().undo().run(),
        },
        {
          label: "Redo",
          icon: "mdi-redo",
          active: () => false, // Redo is not a toggle
          action: () => this.editor.chain().focus().redo().run(),
        },
      ];
    },*/
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    /*this.editor = new Editor({
      extensions: [StarterKit],
      content: "",
      onUpdate: this.handleEditorUpdate,
      onSelectionUpdate: this.handleEditorSelectionUpdate,
    });
    this.editor.setEditable(false);*/
    await this.reloadUsers();
    if (this.value) {
      await this.getTehtava();
    }
  },
  /*beforeDestroy() {
    this.editor.destroy();
  },*/
  watch: {
    /*[`tehtava.description`]() {
      this.editor.commands.setContent(this.tehtava.description);
    },*/
    value() {
      this.isOpen = !!this.value;
      if (this.value && this.value.id) {
        this.getTehtava();
      } else if (this.value && !this.value.id) {
        // eslint-disable-next-line no-undef
        this.tehtava = structuredClone({
          ...newTehtava,
          ...(this.value || {}),
        });
        //this.editor.setEditable(this.hasEditRights);
        // eslint-disable-next-line no-undef
        this.newComment = structuredClone({ ...newComment });
      }
    },
    isOpen() {
      if (!this.isOpen) {
        this.$emit("input", null);
        this.$emit("reload");
        this.$nextTick(() => {
          this.tehtava = {};
        });
      }
    },
  },
  methods: {
    async reloadUsers() {
      this.loadingUsers = true;
      let response = (await api.usersList()).data;
      const users = orderBy(
        response.users,
        ["displayName", "email"],
        ["asc", "asc"]
      );
      users.forEach((u) => {
        u.text = u.displayName;
        u.value = u.email;
      });
      this.users = users;
      this.loadingUsers = false;
    },
    /*handleEditorUpdate() {},
    handleEditorSelectionUpdate() {
      const selection = this.editor.view.state.selection;
      if (!selection.empty && this.hasEditRights) {
        const { from, to } = selection;
        const coords = this.editor.view.coordsAtPos(from);
        const endCoords = this.editor.view.coordsAtPos(to);

        this.menuPosition = {
          x: (coords.left + endCoords.right) / 2,
          y: coords.top - 40, // Position above text
        };
        this.menuVisible = true;
      } else {
        this.menuVisible = false;
      }
    },*/
    async setStatus(status) {
      if (status === "NOT_STARTED") {
        this.loadingStatusNotStarted = true;
      } else if (status === "IN_PROGRESS") {
        this.loadingStatusInProgress = true;
      } else if (status === "READY") {
        this.loadingStatusReady = true;
      }
      try {
        await api.tehtavaHallitusStatus({
          id: this.tehtava.id,
          status: status,
        });
        this.tehtava.status = status;
      } catch (e) {
        console.error(e);
        this.$toast("Tehtävän tilan muuttaminen ei onnistunut", {
          type: "error",
        });
      }
      if (status === "NOT_STARTED") {
        this.loadingStatusNotStarted = false;
      } else if (status === "IN_PROGRESS") {
        this.loadingStatusInProgress = false;
      } else if (status === "READY") {
        this.loadingStatusReady = false;
      }
    },
    async getTehtavaFiles() {
      this.loadingFiles = true;
      try {
        const files = (
          await api.tehtavaHallitusFiles({
            tehtavaId: this.tehtava.id,
          })
        )?.data;

        this.tehtava.files = files.map((f) => {
          f._loadingDeleteFile = false;
          return f;
        });
      } catch (e) {
        console.error(e);
        this.$toast("Tiedostojen haku ei onnistunut", {
          type: "error",
        });
      }
      this.loadingFiles = false;
    },
    async getTehtava(options = {}) {
      if (this.value?.id === this.tehtava.id && !options?.forceReload) return;
      if (!this.value?.id) {
        // eslint-disable-next-line no-undef
        this.tehtava = structuredClone(newTehtava);
      }
      this.loadingTehtava = true;
      const tehtava = (
        await api.tehtavaHallitusGet({
          id: this.value?.id,
        })
      )?.data;

      tehtava.files?.forEach((f) => {
        f._loadingDeleteFile = false;
      });
      tehtava.assignedTo = tehtava.assignedTo?.map((u) => u.email) || [];

      this.tehtava = tehtava;
      //this.ilmoitus = null;
      //this.editor.setEditable(this.hasEditRights);
      this.loadingTehtava = false;
      /*if (this.tehtava?.ilmoitusId) {
        this.reloadIlmoitus();
      }*/
    },
    /*async reloadIlmoitus() {
      if (!this.tehtava?.ilmoitusId) return false;
      this.loadingIlmoitus = true;
      const ilmoitus = (
        await api.ilmoitusHallitusGet({ id: this.tehtava.ilmoitusId })
      ).data;
      if (
        ilmoitus.participants?.findIndex((il) => il.sub === this.$user.uid) > -1
      ) {
        ilmoitus._participant = true;
      }
      this.ilmoitus = ilmoitus;
      this.loadingIlmoitus = false;
    },*/
    async saveFiles() {
      if (!this.tehtava?.id) {
        this.$toast("Tehtävä on tallennettava ennen tiedostojen lisäämistä", {
          type: "error",
        });
        return;
      }
      this.loadingFileSave = true;
      try {
        const files = await this.$refs.upload?.getFiles();
        if (files) {
          for (let filesKey in files) {
            (
              await api.tiedostopankkiHallitusAddFileTehtava({
                tehtavaId: this.tehtava?.id,
                ilmoitusId: this.tehtava.ilmoitusId,
                ...files[filesKey],
              })
            ).data;
          }
        }
        this.$refs.upload?.reset();
      } catch (e) {
        console.error(e);
        this.$toast("Tiedostojen tallennus ei onnistunut", {
          type: "error",
        });
      }
      try {
        await this.getTehtavaFiles();
      } catch (e) {
        console.error(e);
      }
      this.$toast("Tiedostot tallennettu.", {
        type: "success",
      });
      this.loadingFileSave = false;
    },
    async saveTehtava() {
      this.loadingSave = true;
      try {
        // eslint-disable-next-line no-undef
        const tehtava = structuredClone(this.tehtava);
        //tehtava.description = //this.editor.getHTML();
        tehtava.type = this.type;
        tehtava.comments?.forEach((c) => {
          c._deletingComment = false;
        });
        try {
          delete tehtava.ilmoitus;
        } catch (e) {
          console.error(e);
        }
        tehtava.assignedTo =
          tehtava.assignedTo
            ?.map((u) => {
              const user = this.users.find((user) => user.email === u);
              if (!user) return null;
              return {
                email: user.email,
                sub: user.uid,
                user: user.displayName,
              };
            })
            .filter(Boolean) || [];
        await api.tehtavaHallitusUpsert(tehtava);
        this.$toast("Tehtävä lisätty", { type: "success" });
        this.isOpen = false;
      } catch (e) {
        console.error(e);
        this.$toast("Tehtävän lisääminen ei onnistunut", { type: "error" });
        this.loadingSave = false;
      }
      this.loadingSave = false;
    },
    async deleteTehtava() {
      const confirmed = await this.$deleteConfirm(
        "Haluatko varmasti poistaa tehtävän?"
      );

      if (!confirmed) {
        this.loadingDelete = false;
        return;
      }
      this.loadingDelete = true;
      try {
        await api.tehtavaHallitusDelete({ id: this.tehtava.id });
        this.$toast("Tehtävä poistettu", { type: "success" });
        this.isOpen = false;
        this.$emit("reload");
      } catch (e) {
        console.error(e);
        this.$toast("Tehtävän poisto ei onnistunut", { type: "error" });
        this.loadingDelete = false;
      }
      this.$emit("reload");
      this.loadingDelete = false;
    },
    canModifyComment(comment) {
      // Check if current user can modify the comment
      // Replace this with your actual user authentication logic
      return comment.createdBy.sub === this.$user?.uid;
    },
    async addComment() {
      if (!this.newComment.comment.trim() || !this.tehtava.id) return;

      try {
        const commentToSave = {
          ...this.newComment,
          createdBy: {
            email: this.$user.email,
            sub: this.$user.uid,
            user: this.$user.displayName,
          },
          createdAt: new Date().getTime(),
        };

        this.tehtava.comments = [
          ...(this.tehtava.comments || []),
          { ...commentToSave },
        ];
        this.newComment = { ...newComment };
        // Call API to add comment
        await api.tehtavaHallitusKommenttiUpsert({
          ...commentToSave,
          tehtavaId: this.tehtava.id,
        });
        await this.getTehtava({ forceReload: true });

        // Reset comment input
        // eslint-disable-next-line no-undef
        this.newComment = structuredClone({ ...newComment });
      } catch (error) {
        console.error("Failed to add comment", error);
        this.$toast("Kommentin lisäys epäonnistui", { type: "error" });
      }
    },

    async editComment(comment) {
      this.editingComment = { ...comment };
      this.editCommentDialog = true;
    },

    async saveEditedComment() {
      if (!this.editingComment || !this.tehtava.id) return;

      try {
        const editedComment = {
          ...this.editingComment,
          edited: true,
          editedAt: new Date().getTime(),
          editedBy: this.$user?.displayName || "Nimetön käyttäjä",
        };

        this.tehtava.comments = (this.tehtava.comments || []).map((c) =>
          c.id === editedComment.id ? editedComment : c
        );
        // Call API to update comment
        await api.tehtavaHallitusKommenttiUpsert({
          ...editedComment,
          tehtavaId: this.tehtava.id,
        });

        // Reload the task to get updated comments
        await this.getTehtava();

        // Close edit dialog
        this.editCommentDialog = false;
        this.editingComment = null;
      } catch (error) {
        console.error("Failed to edit comment", error);
        this.$toast("Kommentin muokkaus epäonnistui", { type: "error" });
      }
    },

    async deleteComment(comment) {
      comment._deletingComment = true;
      try {
        // Confirm deletion
        const confirmed = await this.$deleteConfirm(
          "Haluatko varmasti poistaa kommentin?"
        );
        if (!confirmed) return;

        this.tehtava.comments = this.tehtava.comments.filter(
          (c) => c.id !== comment.id
        );
        // Call API to delete comment
        await api.tehtavaHallitusKommenttiDelete({
          tehtavaId: this.tehtava.id,
          commentId: comment.id, // Assume comments have unique IDs
        });

        // Reload the task to get updated comments
        await this.getTehtava();
      } catch (error) {
        console.error("Failed to delete comment", error);
        this.$toast("Kommentin poisto epäonnistui", { type: "error" });
      }
      comment._deletingComment = false;
    },
    async openFile(file) {
      try {
        const theBlob = await getBlob(
          ref(tiedostopankkiHallitusStorage, file.key)
        );
        downloadjs(theBlob, file.filename, file.fileType);
      } catch (error) {
        console.error(error);
        this.$toast("Tiedostoa ei voitu avata. Yritä uudelleen.", {
          type: "error",
        });
      }
    },
    async deleteFile(file) {
      if (
        !(await this.$deleteConfirm("Haluatko varmasti poistaa tiedoston?"))
      ) {
        return false;
      }
      this.loadingDeleteFile = true;
      try {
        file._loadingDeleteFile = true;
        await api.tiedostopankkiHallitusDeleteFile(file);

        await this.getTehtavaFiles();
        this.$toast("Tiedosto poistettu", {
          type: "success",
        });
      } catch (e) {
        if (file) {
          file._loadingDeleteFile = false;
        }
        console.error(e);
        this.$toast("Tiedoston poistaminen epäonnistui", {
          type: "error",
        });
      }
      this.loadingDeleteFile = false;
    },
  },
};
</script>
<style lang="scss">
.tiptap {
  min-height: 300px;
  padding: 1em;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 7px;
}
.comment-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &:last-child {
    border-bottom: none;
  }
}
</style>
