<script>
import { collection, getDocs } from "firebase/firestore";
import { firestore as db } from "@/plugins/firebase.app";

const equipmentLocationsTable = "kalusto_sijainnit";

export default {
  name: "KalustoSijainnitDialog",
  emits: ["select"],
  data() {
    return {
      // Type selection dialog data
      dialogTypeSelection: false,
      locations: [], // Raw flat data for types
      selectedLocation: null, // Selected type in the treeview
      selection: [],
      search: null,
    };
  },
  watch: {
    selection(val) {
      if (val?.length > 1) {
        this.selection = [val[1] ?? val[0]];
      }
      if (val[0] !== this.selectedLocation) {
        this.selectedLocation = val[0];
        this.selection = val[0] ? [val[0]] : [];
      }
    },
    search() {},
  },
  computed: {
    // Build hierarchical tree data for types
    treeData() {
      // Create map of types
      const map = new Map(
        this.locations.map((type) => [
          type.id,
          { ...type, children: [], _fullTree: "" },
        ])
      );

      // Link children to their parents (if needed)
      for (const [id, node] of map.entries()) {
        if (node.parent && map.has(node.parent)) {
          map.get(node.parent).children.push(node);
        }
      }

      // Recursive function to build _fullTree
      function buildFullTree(id, map) {
        const node = map.get(id);
        if (!node) return "";

        // If _fullTree already computed, return it
        if (node._fullTree) {
          return node._fullTree;
        }

        // If no parent, _fullTree is just the node's name
        if (!node.parent) {
          node._fullTree = node.name;
          return node._fullTree;
        }

        // Otherwise, recursively get parent's _fullTree and append current node
        const parentFullTree = buildFullTree(node.parent, map);
        node._fullTree = `${parentFullTree} -> ${node.name}`;
        return node._fullTree;
      }

      // Populate _fullTree for all
      for (const [id] of map.entries()) {
        buildFullTree(id, map);
      }

      const tree = [];
      for (const [, node] of map.entries()) {
        if (!node.parent) {
          tree.push(node);
        }
      }
      return tree;
    },
  },
  methods: {
    // Fetch equipment types and build the tree
    async fetchLocations() {
      const snapshot = await getDocs(collection(db, equipmentLocationsTable));
      this.locations = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },

    // Open the type selection dialog
    openLocationDialog() {
      this.dialogTypeSelection = true;
      this.selectedLocation = null;
      this.selection = [];
    },
    selectLocation(loc) {
      this.selectedLocation = loc;
    },
    selectLocationById(locId) {
      if (!locId) return;
      const foundType = this.locations.find((loc) => loc.id === locId);

      if (foundType) {
        this.selection = [foundType];
        //this.selectedLocation = foundType;
      }
    },

    confirmTypeSelection() {
      this.$emit("select", this.selectedLocation);
      this.dialogTypeSelection = false;
    },
  },
  mounted() {
    this.fetchLocations(); // Fetch types on mount
  },
};
</script>

<template>
  <v-dialog v-model="dialogTypeSelection" max-width="600" persistent>
    <v-card>
      <v-card-title>Valitse sijainti</v-card-title>
      <v-divider />
      <v-card-text>
        <v-text-field
          v-model="search"
          label="Hae sijaintia"
          clearable
        ></v-text-field>
        <v-treeview
          :items="treeData"
          v-model="selection"
          item-value="id"
          item-text="name"
          selectable
          :activatable="false"
          open-on-click
          return-object
          open-all
          selection-type="independent"
          :search="search"
          ref="treeview"
        >
        </v-treeview>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialogTypeSelection = false">Peruuta</v-btn>
        <v-btn color="primary" @click="confirmTypeSelection">Valitse</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
