<template>
  <div class="fill-height">
    <v-navigation-drawer
      :value="drawer"
      app
      color="primary"
      dark
      @input="(show) => $store.dispatch('setDrawer', show)"
    >
      <template v-slot:prepend>
        <v-row>
          <v-col
            class="d-flex flex-column justify-center align-center"
            cols="12"
          >
            <v-img
              contain
              height="150"
              max-width="85%"
              src="https://noormarkunvpk.fi/wp-content/uploads/2017/03/logo.png"
            />
            <h1 style="color: #ffffff">Noormarkun VPK</h1>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </template>

      <v-list dense nav>
        <v-list-item
          v-for="route in routes"
          :key="route.name"
          :to="route.path"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ route.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider v-if="$isAdmin" />
      <v-list dense nav>
        <v-list-item
          v-for="route in systemRoutes"
          :key="route.name"
          :to="route.path"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ route.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />

      <template v-slot:append>
        <v-list dense nav>
          <v-list-item link @click="$logout">
            <v-list-item-icon>
              <v-icon>mdi mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Kirjaudu ulos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-list-item class="refresh-list-item" link @click="reload">
            <v-list-item-icon>
              <v-icon>mdi mdi-refresh</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>
                <div class="d-flex align-center justify-lg-space-between">
                  <span :class="[version !== latestVersion && 'info--text']">{{
                    versionShort || "##############"
                  }}</span>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-app-bar :height="$iOS ? 90 : 60" app color="primary" elevation="4">
      <div class="d-flex align-center" style="width: 100%">
        <v-app-bar-nav-icon
          v-if="!prev"
          color="#ffffff"
          @click.stop="$store.dispatch('setDrawer', !drawer)"
        ></v-app-bar-nav-icon>
        <v-btn
          v-if="prev"
          color="white"
          icon
          @click="
            () => {
              if (this.forceFallback) {
                this.$router.push(this.fallback);
                return;
              }
              hasHistory()
                ? this.$router.go(-1)
                : this.$router.push(this.fallback);
            }
          "
        >
          <v-icon>mdi mdi-arrow-left</v-icon>
        </v-btn>
        <h3 style="color: #ffffff">{{ title }}</h3>
        <v-spacer />
        <slot name="app-bar-buttons"> </slot>
        <v-menu offset-y min-width="300" max-height="90vh" max-width="95vw">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="white"
              class="d-flex align-center"
              :loading="loadingNotifications"
            >
              <v-icon>mdi mdi-bell</v-icon>
              <v-badge color="red" overlap v-if="unreadNotifications > 0">
                <template v-slot:badge>
                  {{ String(unreadNotifications) }}
                </template>
              </v-badge>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <h3>Ilmoitukset</h3>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="clearAllNotifications"
                  v-if="notifications.length > 0"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-if="loadingNotifications || notifications.length === 0"
            >
              <v-list-item-content>
                <v-list-item-title class="text-wrap">
                  <h4 v-if="loadingNotifications">Ladataan...</h4>
                  <h4 v-else-if="notifications.length === 0">Ei ilmoituksia</h4>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-for="(notification, index) in paginatedNotifications">
              <v-divider :key="`${notification.id}_divider`" />
              <v-list-item
                :key="notification.id"
                :class="{ 'notification-unread': !notification.read }"
                link
                @click="navigateToRoute(notification.route, index)"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    <span>{{ notification.title }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="mt-3">
                    <div
                      class="text-pre-wrap"
                      style="overflow-wrap: break-word"
                      v-html="notification.description"
                    ></div>
                    <small>{{ formatDate(notification.createdAt) }}</small>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click.stop="markAsRead(index)">
                    <v-icon>{{
                      notification.read
                        ? "mdi-check-circle-outline"
                        : "mdi-bell-ring-outline"
                    }}</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
            <!-- Pagination Controls -->
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col cols="4">
                    <v-btn
                      outlined
                      small
                      :disabled="currentPage === 1"
                      @click.stop="previousPage"
                    >
                      <v-icon>mdi mdi-chevron-left</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-row no-gutters justify="center">
                      <span>Sivu {{ currentPage }} / {{ totalPages }}</span>
                    </v-row>
                  </v-col>
                  <v-col cols="4">
                    <v-row no-gutters justify="end">
                      <v-btn
                        outlined
                        small
                        :disabled="currentPage === totalPages"
                        @click.stop="nextPage"
                        class="ml-2"
                      >
                        <v-icon>mdi mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-main class="fill-height">
      <v-container
        :class="[$vuetify.breakpoint.smAndDown && 'pa-0']"
        :fluid="fluid"
        class="fill-height"
      >
        <v-col class="fill-height pt-5" cols="12">
          <v-alert v-if="$isMissingUserRole" outlined type="error"
            >Sinulla ei ole oikeuksia nähdä sivua. Kirjaudu ulos ja ole
            yhteydessä Joni Rautiaiseen jonirautiainen@gmail.com
          </v-alert>
          <slot></slot>
        </v-col>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { routes } from "@/router";
import api from "@/api";
import dayjs from "dayjs";
import { orderBy } from "lodash";

export default {
  name: "BasicPage",
  components: {},
  props: {
    title: String,
    prev: Boolean,
    fluid: {
      type: Boolean,
      default: true,
    },
    fallback: Object,
    forceFallback: {
      type: Boolean,
      default: false,
    },
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.reloadNotifications);
    if (this.reloadNotificationsInterval) {
      clearInterval(this.reloadNotificationsInterval);
    }
  },
  mounted() {
    if (this.$isMissingUserRole) {
      this.$toast(
        "Ei oikeuksia. Ota yhteyttä Joni Rautiaiseen. Sinut kirjataan ulos 5 sekunnin päästä."
      );
      setTimeout(() => {
        this.$logout();
      }, 5000);
      return;
    } else {
      this.reloadNotifications();
    }
  },
  data() {
    return {
      notifications: [],
      loadingNotifications: false,
      currentPage: 1,
      pageSize: 5, // Number of notifications per page
      reloadNotificationsInterval: null,
    };
  },
  methods: {
    navigateToRoute(route, index) {
      if (route) {
        this.$router.push(route);
      }
      this.markAsRead(index);
    },
    async markAsRead(index) {
      if (this.notifications[index].read) return;
      this.notifications[index].read = true;
      try {
        await api.markNotificationRead({ id: this.notifications[index]?.id });
      } catch (e) {
        console.error(e);
      }
    },
    async clearAllNotifications() {
      this.notifications = [];
      try {
        await api.clearNotifications();
      } catch (e) {
        console.error(e);
      }
    },
    formatDate(date) {
      return dayjs(date).format("DD.MM.YYYY HH:mm:ss");
    },
    hasHistory() {
      return window.history.length > 2;
    },
    reload() {
      if (window.swenv) {
        localStorage.setItem("swenv", window.swenv);
      }

      window.location.reload();
    },
    async reloadNotifications() {
      //if (!this.$isHallitus) return false;
      this.loadingNotifications = true;

      document.removeEventListener(
        "visibilitychange",
        this.reloadNotifications
      );
      document.addEventListener("visibilitychange", this.reloadNotifications);
      try {
        this.notifications = orderBy(
          (await api.listNotifications())?.data,
          ["read", "createdAt"],
          ["asc", "desc"]
        );
      } catch (e) {
        console.error(e);
      }
      this.loadingNotifications = false;
      if (this.reloadNotificationsInterval)
        clearInterval(this.reloadNotificationsInterval);
      this.reloadNotificationsInterval = setInterval(() => {
        this.reloadNotifications();
      }, 60000 * 10);
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.notifications.length / this.pageSize) || 1;
    },
    paginatedNotifications() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      return this.notifications.slice(startIndex, startIndex + this.pageSize);
    },
    version() {
      return window.installedVersion;
    },
    latestVersion() {
      return window.latestVersion;
    },
    versionShort() {
      return (this.version || "").split("-")[0];
    },
    drawer() {
      return this.$store.state.drawer;
    },
    routes() {
      const r = routes.filter((v) => v.nav !== false && !v.part);
      return r.filter((v) => !v.roles || this.$hasRoleIn(v.roles));
    },
    systemRoutes() {
      const r = routes.filter((v) => v.nav !== false && v.part === "system");
      return r.filter((v) => !v.roles || this.$hasRoleIn(v.roles));
    },
    unreadNotifications() {
      return this.notifications.filter((n) => !n.read).length;
    },
  },
};
</script>

<style lang="scss">
.nav-bar {
  background-color: #6c0000;
}

.mb-ios {
  margin-bottom: 90px;
}

.mb-normal {
  margin-bottom: 60px;
}

.refresh-list-item {
}
.notification-unread {
  background-color: rgba(255, 0, 0, 0.1);
}
</style>
