<script>
import BasicPage from "@/components/BasicPage.vue";
import TehtavatHallitus from "@/views/TehtavatHallitus.vue";

export default {
  name: "Tehtavat",
  components: { TehtavatHallitus, BasicPage },
};
</script>

<template>
  <basic-page fluid title="Tehtävät">
    <v-row class="fill-height tehtavat">
      <v-col cols="12" lg="12" offset-xl="2" xl="8">
        <tehtavat-hallitus from="general" />
      </v-col>
    </v-row>
  </basic-page>
</template>

<style scoped lang="scss"></style>
