import { render, staticRenderFns } from "./TiedostopankkiHallitus.vue?vue&type=template&id=5a7c5716&scoped=true"
import script from "./TiedostopankkiHallitus.vue?vue&type=script&lang=js"
export * from "./TiedostopankkiHallitus.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a7c5716",
  null
  
)

export default component.exports