<template>
  <v-card :key="$id(ilmoitus)" class="mt-3 mb-3">
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-action v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-action-text>
              <v-row no-gutters justify="center" class="mb-3">
                <v-icon v-if="ilmoitus.type === 'ilmoitus'" class="" size="36"
                  >mdi mdi-exclamation-thick
                </v-icon>
                <v-icon v-if="ilmoitus.type === 'kokous'" class="" size="36"
                  >mdi mdi-gavel
                </v-icon>
                <v-icon
                  v-if="ilmoitus.type === 'ilmoittautuminen'"
                  class=""
                  size="36"
                  >mdi mdi-account-multiple
                </v-icon>
              </v-row>

              <v-row no-gutters justify="center">
                <v-col cols="12" class="align-content-center">
                  <template v-for="department in departments">
                    <div :key="department" class="d-flex justify-center mt-1">
                      <v-chip x-small>{{ department }}</v-chip>
                    </div>
                  </template>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12">
                  {{
                    $formatDateAndHourMinute(
                      ilmoitus.createdAt || ilmoitus.created
                    )
                  }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  {{
                    (ilmoitus.createdBy && ilmoitus.createdBy.user) ||
                    ilmoitus.user
                  }}
                </v-col>
              </v-row>
            </v-list-item-action-text>
          </v-list-item-action>
          <v-list-item-content
            :class="[$vuetify.breakpoint.smAndDown && 'align-self-start']"
          >
            <v-row no-gutters justify="space-between" align="start">
              <v-col cols="11">
                <v-icon
                  v-if="
                    $vuetify.breakpoint.smAndDown &&
                    ilmoitus.type === 'ilmoitus'
                  "
                  class="mr-2"
                  size="36"
                  >mdi mdi-exclamation-thick
                </v-icon>
                <v-icon
                  v-if="
                    $vuetify.breakpoint.smAndDown && ilmoitus.type === 'kokous'
                  "
                  class="mr-2"
                  size="36"
                  >mdi mdi-gavel
                </v-icon>
                <v-icon
                  v-if="
                    $vuetify.breakpoint.smAndDown &&
                    ilmoitus.type === 'ilmoittautuminen'
                  "
                  class="mr-2"
                  size="36"
                  >mdi mdi-account-multiple
                </v-icon>
                <h2
                  class="text-decoration-underline align-center mb-4"
                  style="align-self: center"
                  :class="[$vuetify.breakpoint.smAndDown && 'd-inline-block']"
                >
                  {{ ilmoitus.type === "kokous" && ilmoitus.orderNumber }}
                  {{ ilmoitus.title }}
                </h2>

                <div v-if="ilmoitus.dateStart" class="mt-2">
                  <v-icon>mdi mdi-calendar-outline</v-icon>
                  <span class="text-subtitle-2">
                    {{
                      $capitalFirstLetter(
                        $formatWeekDay(
                          ilmoitus.dateStart +
                            " " +
                            (ilmoitus.timeStart || "00:00:00")
                        )
                      )
                    }}
                    {{
                      $formatDateAndHourMinute(
                        ilmoitus.dateStart +
                          " " +
                          (ilmoitus.timeStart || "00:00:00")
                      )
                    }}</span
                  >

                  <span v-if="ilmoitus.dateEnd" class="text-subtitle-2">
                    -
                    {{
                      ilmoitus.dateEnd !== ilmoitus.dateStart
                        ? $capitalFirstLetter(
                            $formatWeekDay(
                              ilmoitus.dateEnd +
                                " " +
                                (ilmoitus.timeEnd || "00:00:00")
                            )
                          )
                        : ""
                    }}
                    {{
                      ilmoitus.dateEnd !== ilmoitus.dateStart
                        ? $formatDateAndHourMinute(
                            ilmoitus.dateEnd +
                              " " +
                              (ilmoitus.timeEnd || "00:00:00")
                          )
                        : ilmoitus.timeEnd || "00:00:00"
                    }}</span
                  >
                </div>
                <div v-if="ilmoitus.location" class="mt-2">
                  <v-icon class="mr-1">mdi mdi-map-marker-outline</v-icon>
                  <span class="text-subtitle-2">{{ ilmoitus.location }}</span>
                </div>
              </v-col>
              <v-col cols="1">
                <v-row no-gutters>
                  <v-spacer />
                  <v-btn
                    v-if="hasEditRights(ilmoitus)"
                    class="text-decoration-none"
                    icon
                    @click.stop="moveToIlmoitus(ilmoitus)"
                  >
                    <v-icon size="16">mdi mdi-pencil</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>

            <v-divider class="mt-3" v-if="ilmoitus.content" />
            <v-list-item-subtitle
              v-if="ilmoitus.content"
              class="text-wrap mt-4"
              style="white-space: pre-line !important"
            >
              {{ ilmoitus.content }}
            </v-list-item-subtitle>

            <v-list-item-subtitle
              v-if="ilmoitus.tehtavat && ilmoitus.tehtavat.length > 0"
              class="mt-4"
            >
              <v-divider />
              <v-row class="mt-1">
                <v-col cols="12">
                  <div class="mb-2"><h4>Käsiteltävät asiat</h4></div>

                  <ul class="mb-2" style="list-style: none">
                    <li
                      v-for="tehtava in ilmoitus.tehtavat?.slice(
                        0,
                        sliceAmount
                      )"
                      class="mb-2 ml-n4 text-wrap"
                      :key="tehtava.id"
                    >
                      {{ tehtava.orderNumber }} § {{ tehtava.title }}
                    </li>
                  </ul>
                  <a
                    v-if="sliceAmount < 9999 && ilmoitus.tehtavat.length > 5"
                    @click="sliceAmount = 9999"
                    class="info--text"
                    >Näytä lisää</a
                  >
                  <a
                    v-if="sliceAmount === 9999"
                    @click="sliceAmount = 5"
                    class="info--text"
                    >Näytä vähemmän</a
                  >
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="ilmoitus.files && ilmoitus.files.length > 0"
              class="mt-4"
            >
              <v-divider />
              <v-row class="mt-1">
                <v-col cols="12">
                  <div><h4>Tiedostot</h4></div>
                  <template v-for="(file, i) in ilmoitus.files">
                    <v-list-item-subtitle
                      :key="i"
                      class="text-wrap info--text d-flex align-center mb-3 mt-3 cursor-pointer ml-n1"
                      @click="openFile(file)"
                    >
                      <v-icon>mdi mdi-paperclip</v-icon>
                      <div class="text-wrap" style="word-break: break-word">
                        {{ file.filename }}
                      </div>
                    </v-list-item-subtitle>
                  </template>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <ilmoittautuminen-hallitus
              :ilmoitus.sync="ilmoitus"
              @reload="reloadIlmoitukset"
            />
            <v-divider class="mt-3 mb-3" />
            <v-row
              no-gutters
              v-if="$vuetify.breakpoint.smAndDown"
              class="text-right mb-0 text-body-2 align-self-end"
            >
              <v-col>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="align-content-center">
                    <template v-for="department in departments">
                      <div :key="department" class="d-flex justify-end mt-1">
                        <v-chip x-small>{{ department }}</v-chip>
                      </div>
                    </template>
                  </v-col>
                </v-row>
                <small>
                  <v-row no-gutters>
                    <v-col cols="12">
                      {{
                        $formatDateAndHourMinute(
                          ilmoitus.createdAt || ilmoitus.created
                        )
                      }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      {{
                        (ilmoitus.createdBy && ilmoitus.createdBy.user) ||
                        ilmoitus.user
                      }}
                    </v-col>
                  </v-row>
                </small>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
import { getBlob, ref } from "firebase/storage";
import { tiedostopankkiHallitusStorage } from "@/plugins/firebase.app";
import downloadjs from "downloadjs";
import IlmoittautuminenHallitus from "@/components/Hallitus/IlmoittautuminenHallitus.vue";

export default {
  name: "IlmoitusHallitusListItem",
  components: { IlmoittautuminenHallitus },
  props: {
    ilmoitus: {},
  },
  emits: ["reload"],
  data() {
    return {
      sliceAmount: 5,
    };
  },
  computed: {
    departments() {
      return (
        this.ilmoitus.departments?.map((d) => {
          const deps = {
            hallitus: "Hallitus",
          };
          return deps[d] || d;
        }) || []
      );
    },
  },
  methods: {
    async openFile(file) {
      try {
        const theBlob = await getBlob(
          ref(tiedostopankkiHallitusStorage, file.key)
        );
        downloadjs(theBlob, file.filename, file.fileType);
      } catch (error) {
        console.error(error);
        this.$toast("Tiedostoa ei voitu avata. Yritä uudelleen.", {
          type: "error",
        });
      }
    },
    hasEditRights(ilmoitus) {
      return this.$isHallitus;
    },
    moveToIlmoitus(ilmoitus) {
      this.$router.push({
        name: "HallitusIlmoitusById",
        params: { id: this.$id(ilmoitus) },
      });
    },
    reloadIlmoitukset() {
      this.$emit("reload");
    },
  },
};
</script>
