<template>
  <basic-page title="Digivihko">
    <v-row class="fill-height">
      <v-col cols="12" lg="8" xl="8" offset-xl="2" offset-lg="2">
        <v-card>
          <v-card-title
            >Digivihko
            <v-spacer />
            <div>
              <v-btn color="primary" rounded @click="createDigivihko">
                <v-icon>mdi mdi-plus</v-icon> Uusi digivihko
              </v-btn>
            </div>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <digivihot-table
              :digivihot="digivihot"
              :loading="loading"
              @update:type="(type) => (selectedType = type)"
            >
              <template v-slot:selection>
                <v-chip-group mandatory v-model="year">
                  <v-chip :value="2028" v-if="thisYear >= 2028"> 2028 </v-chip>
                  <v-chip :value="2027" v-if="thisYear >= 2027"> 2027 </v-chip>
                  <v-chip :value="2026" v-if="thisYear >= 2026"> 2026 </v-chip>
                  <v-chip :value="2025" v-if="thisYear >= 2025"> 2025 </v-chip>
                  <v-chip :value="2024" v-if="thisYear >= 2024"> 2024 </v-chip>
                  <v-chip :value="2023" v-if="thisYear >= 2023"> 2023 </v-chip>
                  <v-chip :value="2022" v-if="thisYear >= 2022"> 2022 </v-chip>
                </v-chip-group>
              </template>
            </digivihot-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import dayjs from "dayjs";
import DigivihotTable from "@/components/DigivihotTable";

export default {
  name: "Digivihot",
  components: { DigivihotTable, BasicPage },
  data: () => {
    return {
      loading: false,
      selectedType: "Hälytys",
      year: dayjs().year(),
      thisYear: dayjs().year(),
    };
  },
  computed: {
    today() {
      return dayjs().format("LLLL");
    },
    digivihot() {
      return this.$store.state.digivihot;
    },
  },
  methods: {
    async reloadDigivihot() {
      this.loading = true;
      await this.$store.dispatch("loadDigivihot", this.year);
      this.loading = false;
    },
    reloadAll() {
      this.reloadDigivihot();
    },
    createDigivihko() {
      this.$router.push({
        name: "Digivihko",
        params: { type: this.selectedType },
      });
    },
  },
  watch: {
    year() {
      this.reloadDigivihot();
    },
  },
  mounted() {
    this.reloadAll();
  },
};
</script>

<style scoped lang="scss"></style>
