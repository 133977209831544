<script>
import { collection, getDocs } from "firebase/firestore";
import { firestore as db } from "@/plugins/firebase.app";

const equipmentTypesTable = "kalusto_tyypit";

export default {
  name: "KalustoTyyppiDialog",
  emits: ["select"],
  data() {
    return {
      // Type selection dialog data
      dialogTypeSelection: false,
      equipmentTypes: [], // Raw flat data for types
      selectedType: null, // Selected type in the treeview
      selection: [],
      search: null,
    };
  },
  watch: {
    selection(val) {
      if (val?.length > 1) {
        this.selection = [val[1] ?? val[0]];
      }
      if (val[0] !== this.selectedType) {
        this.selectedType = val[0];
        this.selection = val[0] ? [val[0]] : [];
      }
    },
    search() {},
  },
  computed: {
    // Build hierarchical tree data for types
    treeData() {
      const map = new Map(
        this.equipmentTypes.map((type) => [
          type.id,
          { ...type, children: [], _fullTree: "" },
        ])
      );
      const tree = [];

      map.forEach((node) => {
        if (node.parent) {
          const parent = map.get(node.parent);
          /*node._fullTree = parent._fullTree
            ? [parent._fullTree, node.name].join(" -> ")
            : node.name;*/
          map.get(node.parent)?.children?.push(node);
        } else {
          //node._fullTree = node.name;
          tree.push(node);
        }
        this.buildFullTree(node.id, map);
      });

      return tree;
    },
  },
  methods: {
    buildFullTree(id, map) {
      const node = map.get(id);
      if (!node) return "";

      // If _fullTree already computed, return it
      if (node._fullTree) {
        return node._fullTree;
      }

      // If no parent, _fullTree is just the node's name
      if (!node.parent) {
        node._fullTree = node.name;
        return node._fullTree;
      }

      // Otherwise, recursively get parent's _fullTree and append current node
      const parentFullTree = this.buildFullTree(node.parent, map);
      node._fullTree = `${parentFullTree} -> ${node.name}`;
      return node._fullTree;
    },
    // Fetch equipment types and build the tree
    async fetchEquipmentTypes() {
      const snapshot = await getDocs(collection(db, equipmentTypesTable));
      this.equipmentTypes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },

    // Open the type selection dialog
    openTypeDialog() {
      this.dialogTypeSelection = true;
      this.selectedType = null;
      this.selection = [];
    },
    selectType(type) {
      this.selectedType = type;
    },
    selectTypeById(typeId) {
      if (!typeId) return;
      const foundType = this.equipmentTypes.find((type) => type.id === typeId);

      if (foundType) {
        this.selection = [foundType];
        //this.selectedType = foundType;
      }
    },

    confirmTypeSelection() {
      this.$emit("select", this.selectedType);
      this.dialogTypeSelection = false;
    },
  },
  mounted() {
    this.fetchEquipmentTypes(); // Fetch types on mount
  },
};
</script>

<template>
  <v-dialog v-model="dialogTypeSelection" max-width="600" persistent>
    <v-card class="dialog-card">
      <v-card-title>Valitse tyyppi</v-card-title>
      <v-divider />
      <v-card-text class="dialog-card__content">
        <v-text-field
          v-model="search"
          label="Hae tyyppiä"
          clearable
        ></v-text-field>
        <div class="treeview-container">
          <v-treeview
            :items="treeData"
            v-model="selection"
            item-value="id"
            item-text="name"
            selectable
            :activatable="false"
            open-on-click
            return-object
            open-all
            selection-type="independent"
            :search="search"
            ref="treeview"
          >
            <template v-slot:label="{ item }">
              <div style="text-wrap: auto">{{ item.name }}</div>
            </template>
          </v-treeview>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialogTypeSelection = false">Peruuta</v-btn>
        <v-btn color="primary" @click="confirmTypeSelection">Valitse</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.dialog-card {
  display: flex;
  flex-direction: column;
  max-height: 80vh; /* Limit overall dialog height */
}

.dialog-card__content {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.treeview-container {
  flex: 1;
  overflow-y: auto; /* Enable scrolling for treeview */
  margin-top: 8px; /* Optional spacing */
}
@media (max-width: 599px) {
  :deep(.v-treeview-node__level) {
    width: 12px;
  }
}
</style>
