<template>
  <v-dialog
    ref="dialogModal"
    v-model="modal"
    :return-value.sync="date"
    persistent
    width="320px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :disabled="disabled"
        v-model="dateInput"
        :clearable="clearable"
        :error="error"
        :label="label"
        :hide-details="hideDetails"
        :dense="dense"
        outlined
        :hint="hint"
        :persistent-hint="persistentHint"
        v-bind="attrs"
        v-on="editableDate ? {} : on"
        @blur="handleDateInput(dateInput)"
        :append-icon="editableDate ? 'mdi-calendar' : null"
        @click:append="editableDate ? openDatePicker() : null"
      >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
      </v-text-field>
    </template>

    <v-date-picker
      v-if="type === 'date'"
      v-model="date"
      :allowed-dates="allowedDates"
      header-color="primary"
      scrollable
      show-week
      :required="required"
      first-day-of-week="1"
      :full-width="$vuetify.breakpoint.xsOnly"
    >
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        text
        @click="$refs.dialogModal.save(null)"
        v-if="showClear"
      >
        Tyhjennä
      </v-btn>
      <v-btn color="primary" text @click="modal = false"> Peruuta</v-btn>
      <v-btn color="primary" text @click="$refs.dialogModal.save(date)">
        OK
      </v-btn>
    </v-date-picker>

    <v-date-picker
      v-if="type === 'week'"
      v-model="date"
      :allowed-dates="allowedDates"
      header-color="primary"
      scrollable
      show-week
      first-day-of-week="1"
    >
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        text
        @click="$refs.dialogModal.save(null)"
        v-if="showClear"
      >
        Tyhjennä
      </v-btn>
      <v-btn color="primary" text @click="modal = false"> Peruuta</v-btn>
      <v-btn color="primary" text @click="$refs.dialogModal.save(date)">
        OK
      </v-btn>
    </v-date-picker>

    <v-time-picker v-if="type === 'time'" v-model="date" format="24hr">
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        text
        @click="$refs.dialogModal.save(null)"
        v-if="showClear"
      >
        Tyhjennä
      </v-btn>
      <v-btn color="primary" text @click="modal = false"> Peruuta</v-btn>
      <v-btn color="primary" text @click="$refs.dialogModal.save(date)">
        OK
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
let isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

export default {
  name: "ModalPicker",
  data() {
    return {
      modal: false,
      date: this.value,
      dateInput:
        this.type === "time" ? this.value : this.formatDate(this.value), // Editable date input field
    };
  },
  props: {
    value: String,
    allowedDates: {
      type: Function,
    },
    type: {
      type: String,
      default: "date",
    },
    label: String,
    error: Boolean,
    clearable: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editableDate: {
      type: Boolean,
      default: false, // Determines if the date can be manually edited
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    showClear: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    computedDateFormatted() {
      if (this.type === "time") {
        return this.date;
      }
      if (this.type === "week") {
        return this.formatWeek(this.date);
      }
      return this.formatDate(this.date);
    },
  },
  watch: {
    date(newVal) {
      if (this.type === "time") {
        this.dateInput = newVal; // Sync text field when date changes
      } else {
        this.dateInput = this.formatDate(newVal); // Sync text field when date changes
      }
      this.$emit("input", newVal);
    },
    value(newVal) {
      if (this.type === "time") {
        this.dateInput = newVal; // Sync text field when date changes
      } else {
        this.dateInput = this.formatDate(newVal); // Sync text field with prop changes
      }
      this.date = newVal;
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      return dayjs(date, "YYYY-MM-DD").format("DD.MM.YYYY");
    },
    formatWeek(date) {
      if (!date) return null;
      return dayjs(date).format("WW/GGGG");
    },
    handleDateInput(input) {
      if (this.type !== "time") {
        // Validate and parse date input
        const parsedDate = dayjs(input, "DD.MM.YYYY", true);
        if (parsedDate.isValid()) {
          this.date = parsedDate.format("YYYY-MM-DD"); // Update internal date
        } else {
          this.date = null; // Invalidate the date if input is incorrect
        }
      }
    },
    openDatePicker() {
      this.modal = true; // Open the date picker dialog
    },
  },
};
</script>

<style scoped></style>
